import Cookies from "js-cookie";
import { RequestHandler } from "./Request";

const request = new RequestHandler();

export function GetNotifications(): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      request.get(`notification/owner/get`, token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    }
  });
}
export function MarkAllNotificationAsSeen(): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      request.put(`notification/owner/mark-all-as-seen`, {}, token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    }
  });
}
export function MarkNotificationAsSeen(notificationId:string): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      request.put(`notification/owner/mark-seen/${notificationId}`,{}, token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    }
  });
}
