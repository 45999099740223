import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import SideNav from "../../Components/SideNav";
import ProfileNav from "../../Components/ProfileNav";
import chevBack from "../../assets/ion_chevron-back.svg";
import Input from "../../Components/Input";
import chevDown from "../../assets/chev-down.svg";

function ListReward() {
  const navigate = useNavigate();
  const [tName, setTName] = useState("");
  const [errors, setErrors] = useState<any>({});
  const [activeIndustry, setActiveIndustry] = useState<any>(null);
  const [dropActive, setDropActive] = useState(false);
  const [industries, setIndustries] = useState<any>([]);
  const [activeIndustry1, setActiveIndustry1] = useState<any>(null);
  const [dropActive1, setDropActive1] = useState(false);
  const [industries1, setIndustries1] = useState<any>([]);
  const [isFocused, setIsFocused] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [rtc, setRtc] = useState("");
  const [rewardLink, setRewardLink] = useState("");

  const adjustHeight = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = "auto"; // Reset height to calculate new scroll height
    const scrollHeight = textarea.scrollHeight;
    textarea.style.height = `${Math.min(scrollHeight, 130)}px`;
  };
  const handleFieldChange = (field: string, value: string) => {
    setErrors((prevErrors: any) => {
      const updatedErrors = { ...prevErrors };
      if (updatedErrors[field]) {
        delete updatedErrors[field];
      }
      return updatedErrors;
    });
  };
  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const newErrors: Record<string, string> = {};
    if (tName.trim() === "") {
      newErrors.tName = "Field is required";
    }
    if (rewardLink.trim() === "") {
      newErrors.rewardLink = "Field is required";
    }
    if (rtc.trim() === "") {
      newErrors.rtc = "Field is required";
    }

    // If errors exist, set them and stop submission
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    navigate("/RewardListed");
    setErrors({});
  };
  return (
    <div className="dash-body">
      <SideNav activeTab="Rewards" />
      <div className="content-body">
        <ProfileNav />
        <div
          className="c-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src={chevBack} alt="" />
          <p>Back</p>
        </div>
        <div className="sec-header">
          <p>List New Reward</p>
        </div>
        <div className="list-content-holder">
          <div className="content-cont">
            <Input
              contClass="list-input-cont"
              extraClass="list-input"
              label="Reward Name"
              contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
              value={tName}
              onChange={(e) => {
                setTName(e.target.value);
                handleFieldChange("tName", e.target.value);
              }}
              error={errors.tName}
              errorText={errors.tName}
            />
            <div className="option-holders">
              <div className="dropdown-holder" style={{ width: "100%" }}>
                <div
                  className={`reg-file-cont drop-down ${
                    activeIndustry !== null ? "focused" : ""
                  } ${errors.activeIndustry ? "img-cont-error" : ""}`}
                  onClick={() => {
                    setDropActive(!dropActive);
                  }}
                >
                  <label className="floating-label">Category</label>
                  <div className="active-ind">{activeIndustry?.name}</div>
                  <div className="chev">
                    <img
                      src={chevDown}
                      alt=""
                      className={`${dropActive ? "rotate" : ""}`}
                    />
                  </div>
                </div>
                {errors.activeIndustry && (
                  <p className="error-text">{errors.activeIndustry}</p>
                )}
                {dropActive && (
                  <div className="in-drop-down">
                    {industries.map((item: any, index: number) => {
                      return (
                        <div
                          key={index}
                          className={`ind ${
                            item === activeIndustry ? "ind-active" : ""
                          }`}
                          onClick={() => {
                            setActiveIndustry(item);
                            setDropActive(false);
                            handleFieldChange("voucherVisibility", item.name);
                          }}
                        >
                          {item.name}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className="option-holders">
              <div className="dropdown-holder" style={{ width: "100%" }}>
                <div
                  className={`reg-file-cont drop-down ${
                    activeIndustry1 !== null ? "focused" : ""
                  } ${errors.activeIndustry1 ? "img-cont-error" : ""}`}
                  onClick={() => {
                    setDropActive1(!dropActive1);
                  }}
                >
                  <label className="floating-label">
                    Criteria/Condition To Earn
                  </label>
                  <div className="active-ind">{activeIndustry1?.name}</div>
                  <div className="chev">
                    <img
                      src={chevDown}
                      alt=""
                      className={`${dropActive ? "rotate" : ""}`}
                    />
                  </div>
                </div>
                {errors.activeIndustry1 && (
                  <p className="error-text">{errors.activeIndustry}</p>
                )}
                {dropActive1 && (
                  <div className="in-drop-down">
                    {industries.map((item: any, index: number) => {
                      return (
                        <div
                          key={index}
                          className={`ind ${
                            item === activeIndustry1 ? "ind-active" : ""
                          }`}
                          onClick={() => {
                            setActiveIndustry1(item);
                            setDropActive(false);
                            handleFieldChange("voucherVisibility", item.name);
                          }}
                        >
                          {item.name}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>

            <div
              className={`abt-talent-cont ${
                errors.aboutTalent ? "img-cont-error" : ""
              }`}
            >
              <div className={`abt-label ${isFocused ? "abt-label1" : ""}`}>
                Reward To Be Claimed
              </div>
              <textarea
                ref={textareaRef}
                className="abt-talent-text"
                value={rtc}
                onChange={(e) => {
                  setRtc(e.target.value);
                  adjustHeight(e.target);
                  handleFieldChange("rtc", e.target.value);
                }}
                onFocus={() => setIsFocused(true)}
                onBlur={(e) => setIsFocused(rtc != "" ? true : false)}
              />
            </div>
            {errors.aboutTalent && (
              <p className="error-text">{errors.aboutTalent}</p>
            )}

            <Input
              contClass="list-input-cont"
              extraClass="list-input"
              label="Enter Link for Users To Claim Reward"
              contStyle={{
                height: 64,
                backgroundColor: "#0d0d0d",
                marginTop: 16,
              }}
              value={rewardLink}
              onChange={(e) => {
                setRewardLink(e.target.value);
                handleFieldChange("rewardLink", e.target.value);
              }}
              error={errors.rewardLink}
              errorText={errors.rewardLink}
            />
          </div>
          <div className="std-cont">
            <div className="c-btn" onClick={handleFormSubmit}>
              List reward
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListReward;
