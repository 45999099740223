import React, { CSSProperties } from "react";

interface PProps {
  text?: string;
  disabled?: boolean;
  loading?: boolean; // New loading prop
  onPress?: (e?:any) => void;
  style?: CSSProperties;
  extraClass?: string;
}

export default function SecBtn({
  text,
  disabled,
  loading,
  onPress,
  style,
  extraClass,
}: PProps) {
  return (
    <button
      className={`sec-btn ${
        disabled || loading ? "btn-disabled" : ""
      } ${extraClass}`}
      disabled={disabled || loading} // Disable button when loading
      onClick={onPress}
      style={style}
    >
      {loading ? (
        <div className="dot-loader">
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
        </div>
      ) : (
        text
      )}
    </button>
  );
}
