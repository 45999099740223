import "../../css/profile/styles.css";
import toggleOn from "../../assets/toggle-on.svg";
import toggleOff from "../../assets/toggle-off.svg";
import ngnIcon from "../../assets/nig-flag.svg";
import usdIcon from "../../assets/us-flag.svg";
import eurIcon from "../../assets/eu-flag.svg";
import poundsIcon from "../../assets/pounds-flag.svg";
import chinIcon from "../../assets/chinese-flag.svg";
import { useEffect, useState } from "react";
import { GetUserCurrencyPreference, UpdateUserCurrencyPreference } from "../../RequestHandlers/User";
import Toast, { handleToast } from "../Toast";
import FadeInAnimation from "../FadeIn";

interface Currency {
    name: string;
    value: string;
    icon: string;
    status: boolean;
    border: boolean;
    id: number;
}

interface CurrencyPreferenceProps { }

const CurrencyPreference: React.FC<CurrencyPreferenceProps> = () => {
    const [local, setLocal] = useState<Currency[]>([]);
    const [international, setInternational] = useState<Currency[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const currencyIcons: Record<string, string> = {
        NGN: ngnIcon,
        USD: usdIcon,
        EUR: eurIcon,
        GBP: poundsIcon,
        YUAN: chinIcon,
    };

    const toggleCurrency = async (index: number, isLocal: boolean): Promise<void> => {
        if (isLoading) return;

        const currencyList = isLocal ? local : international;
        const currency = currencyList[index];
        const updatedStatus = !currency.status;

        try {
            setIsLoading(true);
            await UpdateUserCurrencyPreference({
                id: currency.id,
                isActive: updatedStatus,
            });

            if (isLocal) {
                setLocal((prevPreferences) =>
                    prevPreferences.map((pref, i) =>
                        i === index ? { ...pref, status: updatedStatus } : pref
                    )
                );
            } else {
                setInternational((prevPreferences) =>
                    prevPreferences.map((pref, i) =>
                        i === index ? { ...pref, status: updatedStatus } : pref
                    )
                );
            }

            handleToast("Currency preference updated", "success");
        } catch (error) {
            console.error("Failed to update currency preference:", error);
            handleToast("Failed to update currency preference. Please try again.", "error");
        } finally {
            setIsLoading(false);
        }
    };

    const mapBackendDataToState = (data: any[]): void => {
        const localCurrencies = data
            .filter((currency) => currency.type === "local")
            .map((currency) => ({
                name: currency.code,
                value: currency.code.toLowerCase(),
                icon: currencyIcons[currency.code] || "",
                status: currency.isActive,
                border: false,
                id: currency.id,
            }));

        const internationalCurrencies = data
            .filter((currency) => currency.type === "international")
            .map((currency) => ({
                name: currency.code,
                value: currency.code.toLowerCase(),
                icon: currencyIcons[currency.code] || "",
                status: currency.isActive,
                border: true,
                id: currency.id,
            }));

        setLocal(localCurrencies);
        setInternational(internationalCurrencies);
    };

    const getUserPreferenceHandler = async (): Promise<void> => {
        try {
            setIsLoading(true);
            const response = await GetUserCurrencyPreference();
            mapBackendDataToState(response);
        } catch (error) {
            console.error("Error fetching user preferences:", error);
            alert("Error loading preferences. Please refresh the page.");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getUserPreferenceHandler();
    }, []);

    return (
        <div className="preference-view">
            <div className="preference-container">
                <p className="preference-title">Local</p>
                <div className="preference-list-view">
                    {local.map(({ name, status, border, icon }, index) => (
                        <FadeInAnimation delay={index * 0.2} duration={0.5} >
                            <div
                                key={name}
                                className="preference-list"
                                style={{
                                    paddingBottom: border ? "17px" : "0px",
                                    borderBottom: border ? "1px solid #343434 " : "none",
                                }}
                                onClick={() => toggleCurrency(index, true)}
                            >
                                <div className="preference-country-view">
                                    <img src={icon} alt={`${name} icon`} />
                                    <p>{name}</p>
                                </div>
                                <img
                                    src={status ? toggleOn : toggleOff}
                                    alt={`${name} toggle`}
                                    style={{ opacity: isLoading ? 0.5 : 1 }}
                                />
                            </div>
                        </FadeInAnimation>
                    ))}
                </div>
            </div>

            <div style={{ marginTop: 24 }} className="preference-container">
                <p className="preference-title">International</p>
                <div className="preference-list-view">
                    {international.map(({ name, status, border, icon }, index) => (
                        <FadeInAnimation delay={index * 0.3} duration={0.5} >
                            <div
                                key={name}
                                className="preference-list"
                                style={{
                                    paddingBottom: border ? "17px" : "0px",
                                    borderBottom: border ? "1px solid #343434 " : "none",
                                }}
                                onClick={() => toggleCurrency(index, false)}
                            >
                                <div className="preference-country-view">
                                    <img src={icon} alt={`${name} icon`} />
                                    <p>{name}</p>
                                </div>
                                <img
                                    src={status ? toggleOn : toggleOff}
                                    alt={`${name} toggle`}
                                    style={{ opacity: isLoading ? 0.5 : 1 }}
                                />
                            </div>
                        </FadeInAnimation>
                    ))}
                </div>
            </div>
            <Toast />
        </div>
    );
};

export default CurrencyPreference;
