import React, { useState } from "react";
import FadeInAnimation from "./FadeIn";
import Logo1 from "../assets/newLogo.svg";
import { ReactComponent as DashIcon } from "../assets/dashboard.svg";
import { ReactComponent as Project } from "../assets/searching.svg";
import { ReactComponent as Voucher } from "../assets/solar_tag-linear.svg";
import { ReactComponent as Analytic } from "../assets/analytics.svg";
import { ReactComponent as Spotlight } from "../assets/ph_star.svg";
import { ReactComponent as Reward } from "../assets/reward.svg";
import { ReactComponent as Aff } from "../assets/ppl.svg";
import { ReactComponent as Payout } from "../assets/paper.svg";
import { ReactComponent as Refferal } from "../assets/briefcase.svg";
import { ReactComponent as Settings } from "../assets/settings-linear.svg";
import { ReactComponent as Support } from "../assets/customer-care.svg";
import { ReactComponent as LogoutIcon } from "../assets/logout.svg";
import { useNavigate } from "react-router-dom";
import Logout, { handleLogout } from "./Logout";
interface PProps {
  activeTab: string;
}
function SideNav({ activeTab }: PProps) {
  const [expandSideBar, setExpandSideBar] = useState(false);
  const navigate = useNavigate();
  const links = [
    {
      name: "Dashboard",
      delay: 0.4,
      icon: DashIcon,
      screen: "/Dashboard",
    },
    { name: "Projects", delay: 0.6, icon: Project, screen: "/Project" },
    { name: "Vouchers", delay: 0.8, icon: Voucher, screen: "/Voucher" },
    { name: "Analytics", delay: 1, icon: Analytic, screen: "/Project" },
    { name: "Spotlight", delay: 1.2, icon: Spotlight, screen: "/Spotlight" },
    { name: "Rewards", delay: 1.4, icon: Reward, screen: "/Reward" },
    { name: "Affiliate program", delay: 1.6, icon: Aff, screen: "/Project" },
    { name: "Payout", delay: 1.8, icon: Payout, screen: "/Project" },
    { name: "Referral", delay: 2, icon: Refferal, screen: "/Project" },
    { name: "Settings", delay: 2.2, icon: Settings, screen: "/Project" },
    { name: "Support", delay: 2.2, icon: Support, screen: "/Support" },
    { name: "Logout", delay: 2.2, icon: LogoutIcon, screen: "" },
  ];
  return (
    <>
      <div className={`side-nav1 ${expandSideBar ? "side-expand" : ""}`}></div>
      <div className={`side-nav ${expandSideBar ? "side-expand" : ""}`}>
        <FadeInAnimation delay={0.2} duration={1}>
          <div className="logo-wrap">
            <img src={Logo1} alt="" />
            {/* <p>TBS</p> */}
          </div>
        </FadeInAnimation>
        <div className="side-link-list">
          {links.map((item, index) => (
            <FadeInAnimation delay={item.delay} duration={1} key={index}>
              <div
                className={`side-link ${
                  activeTab === item.name ? "active" : ""
                }`}
                style={{
                  marginBottom:
                    index === 9 ? 48 : index === links.length - 1 ? 100 : 10,
                }}
                onClick={() => {
                  if (item.name === "Logout") {
                    handleLogout();
                    console.log("logout");
                  } else {
                    navigate(item.screen);
                  }
                }}
              >
                <div className="overlay"></div>
                <item.icon
                  className="side-img"
                  style={
                    item.icon === Project ||
                    item.icon === Analytic ||
                    item.icon === Spotlight ||
                    item.icon === Reward ||
                    item.icon === Aff ||
                    item.icon === LogoutIcon
                      ? { fill: "currentColor" }
                      : item.icon === Payout
                      ? { stroke: "currentColor" }
                      : { stroke: "currentColor" }
                  }
                />
                <p>{item.name}</p>
              </div>
            </FadeInAnimation>
          ))}
        </div>
        {/* <button
        onClick={() => {
          setExpandSideBar(!expandSideBar);
        }}
      >
        reduce
      </button> */}
      </div>
      <Logout />
    </>
  );
}

export default SideNav;
