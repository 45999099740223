import React, { useState, useRef, useEffect } from "react";
import SideNav from "../../Components/SideNav";
import ProfileNav from "../../Components/ProfileNav";
import { useNavigate } from "react-router-dom";
import plus from "../../assets/outline_plus.svg";
import ValuesCard from "../../Components/ValuesCard";
import fi from "../../assets/Low-Fi.svg";
import arcOutline from "../../assets/arc-outline.svg";
import clock from "../../assets/timer.svg";
import exp from "../../assets/exp.svg";
import vImg from "../../assets/vImg.png";
import verse from "../../assets/verse.png";
import pMenu from "../../assets/pMenu.svg";
import VoucherDetail from "../../Components/VoucherDetail";
import DeleteVoucher from "../../Components/DeleteVoucher";
import RepIssues from "../../Components/RepIssues";
import VHistory from "../../Components/VHistory";
import emptyList from "../../assets/empty-list.svg";
import {
  GetCashBackRequest,
  GetVoucherHolder,
  GetVoucherOverview,
  GetVouchers,
} from "../../RequestHandlers/Voucher";
import { formatDate } from "../../Components/formatDate";
import { TableLoader1 } from "../../Components/TableLoaders";
function Voucher() {
  const navigate = useNavigate();
  const [vLoad, setVLoad] = useState(false);
  const [vHload, setVHLoad] = useState(false);
  const [pHload, setPHLoad] = useState(false);
  const [showDelMenu, setShowDelMenu] = useState(null);
  const [showVoucherDetail, setShowVoucherDetails] = useState(false);
  const [showDelpromt, setShowDelPromt] = useState(false);
  const [cload, setCLoad] = useState(false);
  const [showReportIssue, setShowReportIssue] = useState(false);
  const [vName, setVName] = useState("");
  const [showvHistory, setShowVHistory] = useState(false);
  const [description, setDescription] = useState("");
  const [voucherOverview, setVoucherOverView] = useState<any>([]);
  const [PublishedVoucher, setPublishedVoucheters] = useState<any>([]);
  const hLoadArr = [1, 2, 3];
  const [code, setCode] = useState("");
  const [activeId, setActiveId] = useState("");
  const loaderArr = [1, 2, 3, 4];
  const [cbLoad, setcBLoad] = useState(false);
  const [holderDetails, setHolderDetails] = useState<any>([]);
  const [id, setID] = useState("");

  const [errors, setErrors] = useState({
    vName: "",
    description: "",
  });
  const toggleShowVhoucherDetails = (index: number) => {
    // @ts-ignore
    setShowVoucherDetails(true);
  };
  const toggleMenu = (index: number) => {
    // @ts-ignore
    setShowDelMenu(showDelMenu === index ? null : index);
  };
  const [voucherHolders, setVoucherHolders] = useState<any>([]);
  const [cashBacks, setCashBacks] = useState<any>([]);
  const [vActivities, setVActivities] = useState<any>([]);
  const validateField = (fieldName: string, value: string) => {
    switch (fieldName) {
      case "vName":
        return value.trim() === "" ? "Voucher Number is required" : "";
      case "description":
        return value.trim() === "" ? "Description is required" : "";
      default:
        return "";
    }
  };
  const handleInputChange = (fieldName: string, value: string) => {
    switch (fieldName) {
      case "vName":
        setVName(value);
        break;
      case "description":
        setDescription(value);
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: validateField(fieldName, value),
    }));
  };
  const isFormValid = () => {
    const validationResults = {
      vName: validateField("vName", vName),
      description: validateField("description", description),
    };

    setErrors((prevErrors) => ({
      ...prevErrors,
      ...validationResults,
    }));

    return Object.values(validationResults).every((error) => error === "");
  };

  const handleSubmit = async () => {
    if (isFormValid()) {
      navigate("/IssueReported");
      setShowVoucherDetails(false);
    }
  };

  const getVoucherOverview = async () => {
    setVLoad(true);
    try {
      const res = await GetVoucherOverview();
      setVoucherOverView(res);
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        setVLoad(false);
      }, 2000);
    }
  };
  const getVoucherHolders = async () => {
    setVHLoad(true);
    try {
      const res = await GetVoucherHolder(1, 10);
      setVoucherHolders(res.items);
    } catch (error) {
      console.log(error);
    } finally {
      setVHLoad(false);
    }
  };
  const getVouchers = async () => {
    setPHLoad(true);
    try {
      const res = await GetVouchers(1, 10, "published");
      const acRes = await GetVouchers(1, 10);
      setPublishedVoucheters(res.items);
      setVActivities(acRes.items);
    } catch (error) {
      console.log(error);
    } finally {
      setPHLoad(false);
    }
  };
  const getCbRequest = async () => {
    setcBLoad(true);
    try {
      const res = await GetCashBackRequest(1, 10);
      console.log("cbreq", res);
      setCashBacks(res.items);
    } catch (error) {
      console.log(error);
    } finally {
      setcBLoad(false);
    }
  };
  function capitalizeFirstLetter(word: string): string {
    if (!word) return ""; // Handle empty or null strings
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getVoucherOverview();
    getVoucherHolders();
    getVouchers();
    getCbRequest();
  }, []);
  return (
    <>
      {showvHistory && (
        <div className="voucher-detail">
          <VHistory
            onClose={() => setShowVHistory(false)}
            id={id}
            holderDetails={holderDetails}
          />
        </div>
      )}
      {showVoucherDetail && (
        <div className="voucher-detail">
          {showDelpromt ? (
            <DeleteVoucher
              id={activeId}
              code={code}
              onDelete={() => {
                navigate("/VoucherDeleted");
                setShowVoucherDetails(false);
                setShowDelPromt(false);
              }}
              onClose={() => {
                setShowVoucherDetails(false);
                setShowDelPromt(false);
              }}
              cancel={() => {
                setShowVoucherDetails(false);
                setShowDelPromt(false);
              }}
            />
          ) : showReportIssue ? (
            <RepIssues
              onClose={() => {
                setShowVoucherDetails(false);
                setShowReportIssue(false);
              }}
            />
          ) : (
            <VoucherDetail
              cload={cload}
              id={activeId}
              onClose={() => {
                setShowVoucherDetails(false);
              }}
              onDelete={() => {
                setShowDelPromt(true);
              }}
              showReportIssue={() => setShowReportIssue(true)}
            />
          )}
        </div>
      )}
      <div className="dash-body">
        <SideNav activeTab="Vouchers" />
        <div className="content-body">
          <ProfileNav />
          <div className="section-ph">
            <div className="ph-text-sec">
              <h4>Voucher Management</h4>
              <p>Manage and track your vouchers</p>
            </div>
            <div
              className="lnp-btn"
              onClick={() => {
                navigate("/CreateNewVoucher1");
              }}
            >
              <img src={plus} alt="" /> New voucher
            </div>
          </div>
          <div className="dash-overview">
            <div className="num-holders">
              <ValuesCard
                extraClass={"v-cards"}
                delay={0.5}
                text1="Total vouchers"
                icon={fi}
                loading={vLoad}
                bottomComp={
                  <>
                    <p className="count-value">
                      {voucherOverview.totalActive} listed
                    </p>
                  </>
                }
              />
            </div>
            <div className="num-holders">
              <ValuesCard
                extraClass={"v-cards"}
                delay={0.5}
                text1="Published vouchers"
                icon={arcOutline}
                loading={vLoad}
                bottomComp={
                  <>
                    <p className="count-value">
                      {voucherOverview.totalPublished} published
                    </p>
                  </>
                }
              />
            </div>
            <div className="num-holders">
              <ValuesCard
                extraClass={"v-cards"}
                delay={0.5}
                text1="Pending vouchers"
                icon={clock}
                loading={vLoad}
                bottomComp={
                  <>
                    <p className="count-value">
                      {voucherOverview.totalPending} pending
                    </p>
                  </>
                }
              />
            </div>
            <div className="num-holders">
              <ValuesCard
                extraClass={"v-cards"}
                delay={0.5}
                text1="Expired vouchers"
                icon={exp}
                loading={vLoad}
                bottomComp={
                  <>
                    <p className="count-value">
                      {voucherOverview.totalExpired} expired
                    </p>
                  </>
                }
              />
            </div>
          </div>
          <div className="v-holder">
            <div className="v-head">
              <h4 className="v-head-text">Voucher holders</h4>
              {voucherHolders.length > 3 && (
                <button
                  className="see-all"
                  onClick={() => {
                    navigate("/VoucherHolders");
                  }}
                >
                  See all
                </button>
              )}
            </div>
            <>
              {pHload ? (
                <>
                  <div className="v-cards-wrap">
                    {hLoadArr.map(() => (
                      <div className="voucher-card-load"></div>
                    ))}
                  </div>
                </>
              ) : (
                <>
                  {voucherHolders.length === 0 ? (
                    <div className="empty-wrap" style={{ height: 300 }}>
                      <img src={emptyList} alt="" />
                      <p>No voucher holder</p>
                      <span>List of voucher holders will appear here</span>
                    </div>
                  ) : (
                    <div className="v-cards-wrap">
                      {voucherHolders
                        .slice(0, 3)
                        .map((item: any, index: number) => {
                          return (
                            <div
                              className="voucher-card"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setShowVHistory(true);
                                setID(item.id);
                                setHolderDetails(item);
                              }}
                            >
                              <img src={item.brandVoucher.image} alt="" />
                              <div className="v-card-b">
                                <div className="ball b-left"></div>
                                <div className="ball b-right"></div>
                                <h4 className="h-name">
                                  {item?.user?.firstname} {item?.user?.lastname}
                                </h4>
                                <div className="v-details-wrap">
                                  <div className="v-details-item">
                                    <h4>${item.value?.toLocaleString()}</h4>
                                    <p>{item.code}</p>
                                  </div>
                                  <div className="v-details-item">
                                    <h5>
                                      ${item?.totalSpent?.toLocaleString()}{" "}
                                      value spent
                                    </h5>
                                    <p className="exp">
                                      Expires {formatDate(item.expiry)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </>
              )}
            </>
          </div>
          {cashBacks.length > 0 ? (
            <div className="v-holder v-holder2">
              <div className="v-head">
                <h4 className="v-head-text">Cashback requests</h4>
                {cashBacks.length > 3 && (
                  <button
                    className="see-all"
                    onClick={() => {
                      navigate("/AllCashbackRequest");
                    }}
                  >
                    See all
                  </button>
                )}
              </div>

              <div className="v-cards-wrap v-cards-wrap2">
                {cbLoad ? (
                  <>
                    <div className="v-cards-wrap">
                      {hLoadArr.map(() => (
                        <div className="voucher-card-load"></div>
                      ))}
                    </div>
                  </>
                ) : (
                  <>
                    {cashBacks.length === 0 ? (
                      <div className="empty-wrap" style={{ height: 300 }}>
                        <img src={emptyList} alt="" />
                        <p>No voucher holder</p>
                        <span>List of voucher holders will appear here</span>
                      </div>
                    ) : (
                      <>
                        {cashBacks.map((item: any, index: any) => {
                          return (
                            <div className="voucher-card v-card1">
                              <div className="cb-wrap1">
                                <img src={item.image} alt="" />
                                <div className="cb-wrap1-b">
                                  <h4>{item.addedValue}% added value</h4>
                                  <div className="cb-wrap1-alt">
                                    <p className="exp1">Expired</p>
                                    <h4>{item.code}</h4>
                                  </div>
                                </div>
                              </div>
                              <div className="cb-item">
                                <h4>Voucher price:</h4>
                                <h4>${item?.value?.toLocaleString()}/Unit</h4>
                              </div>
                              <div className="cb-item">
                                <h4>Niche:</h4>
                                <h4>{item.industry}</h4>
                              </div>
                              <div className="cb-item">
                                <h4>Listed units:</h4>
                                <h4>{item.availableUnits?.toLocaleString()}</h4>
                              </div>
                              <div className="cb-item">
                                <h4>Remaining units:</h4>
                                <h4>
                                  {(
                                    item?.availableUnits - item?.unitsSold
                                  )?.toLocaleString()}
                                </h4>
                              </div>
                              <button
                                className="vr-btn vr-btn1"
                                onClick={() => {
                                  navigate("/VoucherCashback", {
                                    state: { id: item.id },
                                  });
                                }}
                              >
                                View requests
                              </button>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className="v-holder v-holder2">
            <div className="v-head">
              <h4 className="v-head-text">Published vouchers</h4>
              {PublishedVoucher.length > 3 && (
                <button
                  className="see-all"
                  onClick={() => {
                    navigate("/PublishedVoucher");
                  }}
                >
                  See all
                </button>
              )}
            </div>
            <div className="v-cards-wrap v-cards-wrap2">
              {vHload ? (
                <>
                  <div className="v-cards-wrap">
                    {hLoadArr.map(() => (
                      <div className="voucher-card-load"></div>
                    ))}
                  </div>
                </>
              ) : (
                <>
                  {PublishedVoucher.slice(0, 3).map(
                    (item: any, index: number) => {
                      return (
                        <div className="voucher-card v-card1">
                          <div className="cb-wrap1">
                            <img src={item.image} alt="" />
                            <div className="cb-wrap1-b">
                              <h4>{item.addedValue}% added value</h4>
                              <div className="cb-wrap1-alt">
                                <p className="exp1 pbl">Published</p>
                                <h4>{item.code}</h4>
                              </div>
                            </div>
                          </div>
                          <div className="cb-item">
                            <h4>Voucher price:</h4>
                            <h4>${item?.value?.toLocaleString()}/Unit</h4>
                          </div>
                          <div className="cb-item">
                            <h4>Niche:</h4>
                            <h4>{item?.industry}</h4>
                          </div>
                          <div className="cb-item">
                            <h4>Listed units:</h4>
                            <h4>{item.availableUnits?.toLocaleString()}</h4>
                          </div>
                          <div className="cb-item">
                            <h4>Remaining units:</h4>
                            <h4>
                              {(
                                item?.availableUnits - item?.unitsSold
                              )?.toLocaleString()}
                            </h4>
                          </div>
                          <div className="vd-wrap">
                            <button
                              className="vr-btn vr-btn2"
                              onClick={() => {
                                toggleShowVhoucherDetails(index);
                                setActiveId(item.id);
                                setCode(item.code);
                              }}
                            >
                              View details
                            </button>
                            <div>
                              <button
                                style={{ all: "unset", cursor: "pointer" }}
                                onClick={() => {
                                  toggleMenu(index);
                                }}
                              >
                                <img src={pMenu} alt="" />
                              </button>
                              {showDelMenu === index && (
                                <div className="delMenu">
                                  <button
                                    onClick={() => {
                                      setShowDelMenu(null);
                                    }}
                                  >
                                    Unpublish
                                  </button>
                                  <button
                                    onClick={() => {
                                      setShowDelMenu(null);
                                    }}
                                  >
                                    Delete
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </>
              )}
            </div>
          </div>
          <div className="v-holder v-holder2">
            <div className="v-head">
              <h4 className="v-head-text">Voucher activity</h4>
              {vActivities.length > 3 && (
                <button
                  className="see-all"
                  onClick={() => {
                    navigate("/VoucherActivity");
                  }}
                >
                  See all
                </button>
              )}
            </div>
            {vActivities.length === 0 ? (
              <>
                <div className="empty-wrap" style={{ height: 300 }}>
                  <img src={emptyList} alt="" />
                  <p>No voucher activity available</p>
                  <span>List of voucher activities will appear here</span>
                </div>
              </>
            ) : (
              <table className="activity-table">
                <thead>
                  <tr>
                    <th className="th-first">Voucher number</th>
                    <th>Date created</th>
                    <th>Status</th>
                    <th>Reach</th>
                    <th>Total amount invested</th>
                    <th>Investor count</th>
                  </tr>
                </thead>
                {pHload ? (
                  <>
                    <TableLoader1 loaderArr={loaderArr} />
                  </>
                ) : (
                  <tbody>
                    {vActivities.slice(0, 3).map((item: any, index: any) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div className="tr tr2">{item.code}</div>
                          </td>
                          <td>
                            <div className="tr1 tr2">
                              {formatDate(item.created_at)}
                            </div>
                          </td>
                          <td className={`tr1 tr2`}>
                            <p
                              className={`av-status ${
                                item.status.toLowerCase().includes("pending")
                                  ? "av-pending"
                                  : item?.status
                                      ?.toLowerCase()
                                      ?.includes("rejected")
                                  ? "av-rejected"
                                  : ""
                              }`}
                            >
                              {item.status === "pending"
                                ? "Pending approval"
                                : capitalizeFirstLetter(item?.status)}
                            </p>
                          </td>
                          <td>
                            <div className="tr1 tr2">
                              Seen by {item?.viewCount} people
                            </div>
                          </td>
                          <td>
                            <div className="tr1 tr2">
                              {item?.stats?.totalInvestedAmount}
                            </div>
                          </td>
                          <td>
                            <div className="tr-last1">
                              {item?.stats?.uniqueInvestorsCount}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Voucher;
