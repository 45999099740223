import React, { useState, useRef, useEffect } from "react";
import SideNav from "../../../Components/SideNav";
import ProfileNav from "../../../Components/ProfileNav";
import chevBack from "../../../assets/ion_chevron-back.svg";
import { useNavigate } from "react-router-dom";
import ProgressRing from "../../../Components/ProgressRing";
import Input from "../../../Components/Input";
import uploadImg from "../../../assets/upload.svg";
import chevDown from "../../../assets/chev-down.svg";
import { GetIndustries } from "../../../RequestHandlers/Auth";
import colon from "../../../assets/colon.svg";
import NumberMonthDropdown from "../../../Components/NumberMonthDropdown";
import plus from "../../../assets/plus.svg";
import TextArea from "../../../Components/Textera";
import { useLocation } from "react-router-dom";
import { useFileUpload } from "../../../context/uploadFile";
import roundedCheck from "../../../assets/rc.svg";
import uploadedImg from "../../../assets/uploadedImg.svg";
import gallary from "../../../assets/gallery.svg";
import trash from "../../../assets/trash.svg";
import ActivityIndicator from "../../../Components/ActivityIndicator";

interface Row {
  months: string;
  roi: string;
}
interface fwork {
  title: string;
  document: string;
  link: string;
  fileName?: string;
}

function ListProject2() {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state.data || {};
  const [tName, setTName] = useState("");
  const [uploading, setUpLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [uploadedFile, setUpLoadedFile] = useState<any>(null);
  const [riskfactor, setRiskFactor] = useState([""]);
  const [mts, setmts] = useState([""]);
  const [competitors, setCompetitors] = useState<string[]>(["", "", ""]);
  const [advantages, setAdvantages] = useState<string[]>(["", "", ""]);
  const { uploadFile, isUploading, uploadError } = useFileUpload();
  const [supportingDoc, setSupportingDoc] = useState<fwork[]>([
    { title: "", document: "", link: "", fileName: "" },
  ]);
  const [uploadedFileUrl, setUploadedFileUrl] = useState<string | null>(null);
  const [hangedFile, setHangedFile] = useState<any>(null);
  const [errors, setErrors] = useState<any>({});
  const handleAddrf = () => {
    setRiskFactor([...riskfactor, ""]);
  };
  const handleAddmts = () => {
    setmts([...mts, ""]);
  };
  const handleAddMoreDoc = () => {
    setSupportingDoc([
      ...supportingDoc,
      { title: "", document: "", link: "", fileName: "" },
    ]);
  };
  const handleAddCompetitor = () => {
    setCompetitors([...competitors, ""]);
  };
  const handleAddAdvantages = () => {
    setAdvantages([...advantages, ""]);
  };

  const handleAdvantagesChange = (index: number, value: string) => {
    const updatedSkills = [...advantages];
    updatedSkills[index] = value;
    setAdvantages(updatedSkills);
  };

  const handleCompetitorsChange = (index: number, value: string) => {
    const updatedSkills = [...competitors];
    updatedSkills[index] = value;
    setCompetitors(updatedSkills);
  };
  const handleRiskfactor = (index: number, value: string) => {
    const updatedSkills = [...riskfactor];
    updatedSkills[index] = value;
    setRiskFactor(updatedSkills);
  };

  const handleMtsChange = (index: number, value: string) => {
    const updatedSkills = [...mts];
    updatedSkills[index] = value;
    setmts(updatedSkills);
  };

  const fileInputRefs = useRef<HTMLInputElement[]>([]);
  // Initialize refs
  useEffect(() => {
    fileInputRefs.current = supportingDoc.map(
      (_, i) => fileInputRefs.current[i] || React.createRef<HTMLInputElement>()
    );
  }, []);

  const handleInputChangeDoc = (
    index: number,
    field: keyof fwork,
    value: string
  ) => {
    setSupportingDoc((prev) =>
      prev.map((item, idx) =>
        idx === index
          ? {
              ...item,
              [field]: value,
              ...(field === "link" ? { document: "" } : {}),
              ...(field === "document" ? { link: "" } : {}),
            }
          : item
      )
    );
  };

  const adjustHeight = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = "auto"; // Reset height to calculate new scroll height
    const scrollHeight = textarea.scrollHeight;
    textarea.style.height = `${Math.min(scrollHeight, 130)}px`;
  };
  const handleFieldChange = (field: string, value: string) => {
    setErrors((prevErrors: any) => {
      const updatedErrors = { ...prevErrors };
      if (updatedErrors[field]) {
        delete updatedErrors[field];
      }
      return updatedErrors;
    });
  };
  const handleFileSelect = async (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.files && e.target.files.length > 0) {
      try {
        const file = e.target.files[0];
        console.log(file);

        const data = await uploadFile(file); // Replace with your actual upload logic
        console.log("Uploaded file:", data);

        if (data.secure_url) {
          // setSupportingDoc((prev) =>
          //   prev.map((item, idx) =>
          //     idx === index ? { ...item, document: data.secure_url } : item
          //   )
          // );
          handleInputChangeDoc(index, "document", data.secure_url);
          setSupportingDoc((prev) =>
            prev.map((item, idx) =>
              idx === index
                ? {
                    ...item,
                    document: data.secure_url,
                    fileName: file.name, // Add the file name
                    link: "", // Clear the link field
                  }
                : item
            )
          );
          handleFieldChange(`dd${index}`, data.secure_url);
          handleFieldChange(`ll${index}`, data.secure_url);
        } else {
          console.error("File upload failed");
        }
      } catch (error) {
        console.error("Upload failed:", error);
      }
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // const tryFileUploadAgain = async () => {
  //   try {
  //     const data = await uploadFile(hangedFile);
  //     setUploadedFileUrl(data.secure_url);
  //     if (data.secure_url) {
  //       setSelectedFile(data);
  //     } else {
  //       setSelectedFile(null);
  //     }
  //   } catch (error) {
  //     console.error("Upload failed:", error);
  //   }
  // };
  const removeUploadedFile = (index: number) => {
    setSupportingDoc((prev) =>
      prev.map((item, idx) =>
        idx === index ? { ...item, document: "", fileName: "" } : item
      )
    );

    // console.log(supportingDoc.splice(index,1));
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const newErrors: Record<string, string> = {};
    competitors.slice(0, 3).forEach((item, index) => {
      if (item === "") {
        newErrors[`comp${index}`] = "Field is required";
      }
    });
    advantages.slice(0, 3).forEach((item, index) => {
      if (item === "") {
        newErrors[`ad${index}`] = "Field is required";
      }
    });
    supportingDoc.slice(0, 1).forEach((item, index) => {
      if (item.title === "") {
        newErrors[`tt${index}`] = "Field is required";
      }
      if (item.document === "" && item.link === "") {
        newErrors[`dd${index}`] = "Field is required";
        newErrors[`ll${index}`] = "Field is required";
      } else if (item.document !== "" && item.link !== "") {
        newErrors[`dd${index}`] = "Field is required";
        newErrors[`ll${index}`] = "Field is required";
      }
    });
    riskfactor.slice(0, 1).forEach((item, index) => {
      if (item === "") {
        newErrors[`rf${index}`] = "Field is required";
      }
    });
    mts.slice(0, 1).forEach((item, index) => {
      if (item === "") {
        newErrors[`mts${index}`] = "Field is required";
      }
    });
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    console.log(
      "submited",
      competitors,
      advantages,
      supportingDoc,
      riskfactor,
      mts
    );

    setErrors({});
    navigate("/ProjectCompanyDetails", {
      state: {
        data: data,
        data2: {
          competitors: competitors,
          advantages: advantages,
          supportingDoc: supportingDoc,
          riskfactor: riskfactor,
          mts: mts,
        },
      },
    });
  };
  return (
    <div className="dash-body">
      <SideNav activeTab="Projects" />
      <div className="content-body">
        <ProfileNav />
        <div
          className="c-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src={chevBack} alt="" />
          <p>Back</p>
        </div>
        <div className="sec-header">
          <p>List a Project</p>
          <ProgressRing
            radius={20}
            stroke={4}
            progress={50}
            text={"2/4"}
            backgroundColor="#111900"
          />
        </div>
        <div className="list-content-holder">
          <div className="content-cont">
            <p className="ov">Industry competitors</p>
            <div className="skills-cont">
              {competitors.map((item, index) => {
                return (
                  <div className="skill-input">
                    <Input
                      contClass="list-input-cont"
                      extraClass="list-input"
                      label="Enter competitors"
                      contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                      value={item}
                      onChange={(e) => {
                        handleCompetitorsChange(index, e.target.value);
                        handleFieldChange(`comp${index}`, e.target.value);
                      }}
                      error={errors[`comp${index}`]}
                      errorText={errors[`comp${index}`]}
                    />
                  </div>
                );
              })}
            </div>

            <div className="dash"></div>
            <div className="inc-btn-cont">
              <div
                className="add-btn"
                onClick={() => {
                  handleAddCompetitor();
                }}
              >
                <img src={plus} alt="" /> Add more
              </div>
            </div>
          </div>
          <div className="content-cont">
            <p className="ov">Competitive advantages</p>
            <div className="skills-cont">
              {advantages.map((item, index) => {
                return (
                  <div className="skill-input">
                    <Input
                      contClass="list-input-cont"
                      extraClass="list-input"
                      label="Enter advantage"
                      contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                      value={item}
                      onChange={(e) => {
                        handleAdvantagesChange(index, e.target.value);
                        handleFieldChange(`ad${index}`, e.target.value);
                      }}
                      error={errors[`ad${index}`]}
                      errorText={errors[`ad${index}`]}
                    />
                  </div>
                );
              })}
            </div>

            <div className="dash"></div>
            <div className="inc-btn-cont">
              <div
                className="add-btn"
                onClick={() => {
                  handleAddAdvantages();
                }}
              >
                <img src={plus} alt="" /> Add more
              </div>
            </div>
          </div>
          <div className="content-cont">
            <p className="ov">
              Supporting documents (business plan, pitch deck, etc)
            </p>
            {supportingDoc.map((item, index) => {
              return (
                <>
                  <Input
                    contClass="list-input-cont cc1"
                    extraClass="list-input"
                    label="Title"
                    contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                    value={item.title}
                    onChange={(e) => {
                      handleInputChangeDoc(index, "title", e.target.value);
                      handleFieldChange(`tt${index}`, e.target.value);
                    }}
                    error={errors[`tt${index}`]}
                    errorText={errors[`tt${index}`]}
                  />
                  <div
                    className={`img-upload-cont ${
                      item.fileName ? "img-upload-cont1" : ""
                    } ${errors[`dd${index}`] ? "img-cont-error" : ""}`}
                    onClick={() => {
                      // @ts-ignore

                      // @ts-ignore
                      if (item.link === "") {
                        // @ts-ignore
                        fileInputRefs[index].click();
                      }
                    }}
                    style={{ cursor: item.link ? "not-allowed" : "pointer" }}
                  >
                    <input
                      type="file"
                      // @ts-ignore
                      ref={(el) => (fileInputRefs[index] = el)}
                      style={{ display: "none" }}
                      onChange={(e) => handleFileSelect(index, e)}
                    />
                      <div className="upp-nav">
                         <p>Upload Document</p>
                        {isUploading ? (
                          <ActivityIndicator size="24px" color="#a7fc00" />
                        ) : item.fileName ? (
                          <img src={roundedCheck} />
                        ) : <></>}
                      </div>
                  
                    <img src={item.fileName ? uploadedImg : uploadImg} alt="" />
                    <p>
                      {" "}
                      {item.fileName
                        ? "Document Uploaded Successfully"
                        : "Upload Document"}
                    </p>
                    <span
                      onClick={() => {
                        if (uploadError) {
                          // tryFileUploadAgain();
                        }
                      }}
                    >
                      {item.fileName
                        ? `${item.fileName}`
                        : uploadError
                        ? "Try again"
                        : "(375*150 recommended)"}
                    </span>
                    {item.fileName && (
                      <div className="bbb-nav">
                        <div className="nn-wrap">
                          <img src={gallary} alt="" />
                          <p>{item.fileName}</p>
                        </div>

                        <img
                          src={trash}
                          alt=""
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            removeUploadedFile(index);
                            console.log("sg");
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <Input
                    contClass="list-input-cont cc"
                    extraClass={`list-input ${
                      item.document != "" ? "cus" : ""
                    }`}
                    label="or enter document link"
                    contStyle={{
                      height: 64,
                      // cursor: item.document != "" ? "not-allowed" : "auto",
                      backgroundColor: "#0d0d0d",
                    }}
                    value={item.link}
                    labelStyle={{ fontSize: 14 }}
                    disabled={item.document != ""}
                    onChange={(e) => {
                      handleInputChangeDoc(index, "link", e.target.value);
                      handleFieldChange(`ll${index}`, e.target.value);
                      handleFieldChange(`dd${index}`, e.target.value);
                    }}
                    error={errors[`ll${index}`]}
                    errorText={errors[`ll${index}`]}
                  />
                </>
              );
            })}

            <div className="dash"></div>
            <div className="inc-btn-cont">
              <div
                className="add-btn"
                onClick={() => {
                  handleAddMoreDoc();
                }}
              >
                <img src={plus} alt="" /> Add document
              </div>
            </div>
          </div>

          <div className="content-cont">
            <p className="ov">Risk assessment</p>
            <div className="rf">
              <p>Risk factors</p>
              {riskfactor.map((item, index) => {
                return (
                  <div className="tt-cont">
                    <TextArea
                      height={64}
                      extraClass="textarea rf-input"
                      label="Description"
                      bgColor="#0d0d0d"
                      value={item}
                      onChange={(e) => {
                        handleRiskfactor(index, e.target.value);
                        handleFieldChange(`rf${index}`, e.target.value);
                      }}
                      error={errors[`rf${index}`]}
                      errorText={errors[`rf${index}`]}
                    />
                    {/* {errors[`rf${index}`] && (
                      <p className="error-text">{errors[`rf${index}`]}</p>
                    )} */}
                  </div>
                );
              })}
              <div className="dash"></div>
              <div className="inc-btn-cont">
                <div
                  className="add-btn"
                  onClick={() => {
                    handleAddrf();
                  }}
                >
                  <img src={plus} alt="" /> Add more
                </div>
              </div>
            </div>
            <div className="rf">
              <p>Mitigation strategies</p>
              {mts.map((item, index) => {
                return (
                  <div className="tt-cont">
                    <TextArea
                      height={64}
                      extraClass="textarea rf-input"
                      label="Description"
                      bgColor="#0d0d0d"
                      value={item}
                      onChange={(e) => {
                        handleMtsChange(index, e.target.value);
                        handleFieldChange(`mts${index}`, e.target.value);
                      }}
                      error={errors[`mts${index}`]}
                      errorText={errors[`mts${index}`]}
                    />
                  </div>
                );
              })}
              <div className="dash"></div>
              <div className="inc-btn-cont">
                <div
                  className="add-btn"
                  onClick={() => {
                    handleAddmts();
                  }}
                >
                  <img src={plus} alt="" /> Add more
                </div>
              </div>
            </div>
          </div>
          <div className="std-cont">
            <div className="std-btn">Save to draft</div>
            <div className="c-btn" onClick={handleFormSubmit}>
              Continue
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListProject2;
