import React, { useEffect, useState } from "react";
import search from "../assets/search.svg";
import notification from "../assets/notification.svg";
import profile from "../assets/profile.png";
import FadeInTop from "./FadeInTop";
import { GetUserDetails } from "../RequestHandlers/User";
import NotificationDropDown from "./navigation-dropdowns/notification-drop-down";
import ProfileDropDown from "./navigation-dropdowns/profile-drop-down";
import c5 from "../assets/c5.png";
import retry from "../assets/retry.svg";
import { useNavigate } from "react-router-dom";
import { GetAllSpotlight } from "../RequestHandlers/Spotlight";
import { GetProjects } from "../RequestHandlers/Collection";

interface PProps {
  showSearch?: true | false;
}

interface PaginatedItems<T> {
  items: T[];
  meta: {
    currentPage: number;
    itemsPerPage: number;
    totalPages: number;
    itemCount: number;
    totalItems: number;
  };
}

interface Item {
  name: string;
  industry: string;
  location: string;
  img: string;
}

function ProfileNav({ showSearch = true }: PProps) {
  const [openNot, setOpenNot] = useState(false);
  const [openProf, setOpenProf] = useState(false);
  const [details, setdetails] = useState<any>([]);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [searchWord, setSearchword] = useState("");
  const [searchData, setSearchData] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [spotlightData, setSpotlightData] = useState<PaginatedItems<Item>>({
    items: [],
    meta: {
      currentPage: 0,
      itemsPerPage: 0,
      totalPages: 0,
      itemCount: 0,
      totalItems: 0,
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Get user details (for profile image and other details)
  const getUserDetails = async () => {
    try {
      const res = await GetUserDetails();
      if (res.avatar) {
        setdetails(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProject = async () => {
    setLoading(true);
    try {
      const res = await GetProjects(1, 4, "all", searchWord);
      // console.log(res);
      if (res.items) {
        setData(res.items);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // Get spotlight data from the API
  const getAllSpotlight = async () => {
    setIsLoading(true);
    try {
      const res = await GetAllSpotlight();
      setSpotlightData(res); // Update spotlightData with the API response
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    getUserDetails();
  }, []); 
  
  
  useEffect(() => {
    const timer = setTimeout(() => {
      getProject();
    getAllSpotlight();
  }, 500);
  return () => clearTimeout(timer); // Cleanup on every searchWord change
  }, [searchWord]); 


  // Merge and normalize project and spotlight data
  const mergedSearchData = [
    ...spotlightData.items.map((item: any) => ({
      id: item.id,
      title: item.title,
      resultType: "spotlight", 
      type: item.type,
      location: item.owner?.location || item.location,
      cover: item.cover || c5, // Use a fallback image
    })),
    ...data.map((item: any) => ({
      id: item.id,
      title: item.name,
      resultType: "project", 

      type: item.industry,
      location: item.officeLocation,
      cover: item.cover || c5,
    })),
  ];

  // Filter the merged data
  const filteredData = mergedSearchData.filter((item: any) => {
    return (
      item.title?.toLowerCase().includes(searchWord.toLowerCase()) ||
      item.type?.toLowerCase().includes(searchWord.toLowerCase()) ||
      item.location?.toLowerCase().includes(searchWord.toLowerCase())
    );
  });

  // Update search data after spotlight data and projects are fetched
  useEffect(() => {
    setSearchData(mergedSearchData); // Set merged data to searchData
  }, [spotlightData, data]); // This runs when either spotlightData or data is updated

  return (
    <div
      className="pr-nav"
      style={{ backgroundColor: "#000", position: "fixed" }}
    >
      <FadeInTop delay={0.5} duration={1}>
        {showSearch ? (
          <div
            className={`custom-input ${
              isSearchFocused || searchWord ? "showSBorder" : ""
            }`}
          >
            <img src={search} alt="" />
            <input
              type="text"
              placeholder="Search"
              className="w-full"
              value={searchWord}
              onChange={(e) => setSearchword(e.target.value)}
              onFocus={() => setIsSearchFocused(true)}
              onBlur={() => {
                if (!searchWord) {
                  setIsSearchFocused(false);
                }
              }}
            />
          </div>
        ) : (
          <div></div>
        )}
        {isSearchFocused || searchWord ? (
          <div className="search-it-holder">
            {searchWord && (
              <>
                {filteredData.length === 0 ? (
                  <div
                    className="empty-wrap"
                    style={{ width: "100%", height: 200 }}
                  >
                    <img src={retry} alt="" />
                    <p>Try again</p>
                    <span>No results found for your search.</span>
                  </div>
                ) : (
                  <>
                    <div className="search-it-header">
                      <p>
                        {filteredData.length} results found for {searchWord}
                      </p>
                      <button
                        onClick={() => {
                          navigate("/SearchScreen", {
                            state: { searchValue: searchWord },
                          });
                        }}
                      >
                        See all
                      </button>
                    </div>
                    {filteredData.slice(0,4).map((item: any, index: number) => {
                      return (
                        <div
                          onClick={() => {
                            // Check if it's a project or spotlight and navigate accordingly
                            if (item.resultType === "project") {
                              // Navigate to the project details page
                              navigate(`/ProjectDetailsScreen`, {
                                state: {
                                  data: { name: item.title, id: item.id },
                                },
                              });
                            } else {
                              // Navigate to the spotlight details page
                              navigate(`/SpotlightDetails`, {
                                state: { id: item.id },
                              });
                            }
                          }}
                          className="search-item"
                          key={index}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 12,
                            }}
                          >
                            <img
                              src={item.cover || c5} // Fallback image if not available
                              alt=""
                              style={{
                                width: 40,
                                height: 40,
                                borderRadius: 100,
                              }}
                            />
                            <div>
                              <p
                                style={{
                                  fontSize: "1rem",
                                  color: "#fff",
                                  fontWeight: 500,
                                }}
                              >
                                {item.title}
                              </p>
                              <p className="ssub-text capitalize">{item.type}</p>
                            </div>
                          </div>
                          <p className="ssub-text capitalize">{item?.resultType}</p>
                        </div>
                      );
                    })}
                  </>
                )}
              </>
            )}
          </div>
        ) : (
          <></>
        )}
      </FadeInTop>

      <div className="noti-profile">
        <FadeInTop delay={1} duration={1}>
          <div onClick={() => setOpenNot(!openNot)}>
            <img src={notification} alt="" />
          </div>
        </FadeInTop>
        <FadeInTop delay={1.5} duration={1}>
          <div onClick={() => setOpenProf(!openProf)}>
            <img src={details.avatar} alt="" className="profile-img" />
          </div>
        </FadeInTop>
      </div>
      <NotificationDropDown setOpenNot={setOpenNot} openNot={openNot} />
      <ProfileDropDown setOpenProf={setOpenProf} openProf={openProf} />
    </div>
  );
}

export default ProfileNav;
