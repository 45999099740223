import React, { useEffect, useState, useRef } from "react";
import ValuesCard from "../../Components/ValuesCard";
import dolls from "../../assets/dolls.svg";
import arc from "../../assets/arc.svg";
import ppl from "../../assets/ppl.svg";
import impression from "../../assets/Impression.svg";
import pfi from "../../assets/pfi.svg";
import {
  GetCollectionFundChart,
  GetCollectionOverview,
  GetCollectionTotalFund,
} from "../../RequestHandlers/Collection";
import dropdownicon from "../../assets/icon-park_down.svg";
import LargeChart from "../../Components/LargeChart";
import emptyChart from "../../assets/empty-chart.svg";

interface PProps {
  id: number;
}
function Financails({ id }: PProps) {
  const [vLoad, setVLoad] = useState(false);
  const [fundingProgress, setFundingProgress] = useState(0);
  const [overView, setOverView] = useState<any>([]);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [activeValue, setActiveValue] = useState("Last 14 days");
  const [activeDuration, setActiveDuration] = useState("last-14-days");
  const [cload, setCLoad] = useState(false);
  const cloadArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [totalFund, setTotalFund] = useState(0);

  const [chartData, setChartData] = useState<any>([]);

  const chartDurations = [
    { mDate: "Last 14 days", bDate: "last-14-days" },
    { mDate: "Last 30 days", bDate: "last-30-days" },
    { mDate: "Last 60 days", bDate: "last-60-days" },
    { mDate: "Last 90 days", bDate: "last-90-days" },
    { mDate: "Last 120 days", bDate: "last-120-days" },
  ];

  const getCollectionOverview = async () => {
    try {
      const res = await GetCollectionOverview(id);
      setOverView(res);
      setFundingProgress(res.percentageFundsRaised);
    } catch (error) {
      console.log(error);
    } finally {
      setVLoad(false);
    }
  };

  const getFundsChart = async () => {
    try {
      const res = await GetCollectionFundChart(id, activeDuration);
      setChartData(res.data);
      console.log(res);
    } catch (error) {
      console.log(error);
    } finally {
      setCLoad(false);
    }
  };

  const getTotalFund = async () => {
    try {
      const res = await GetCollectionTotalFund(id);
      setTotalFund(res.total);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setVLoad(true);
    setCLoad(true);
    getCollectionOverview();
    getFundsChart();
    getTotalFund();
  }, []);

  useEffect(() => {
    setCLoad(true);
    getFundsChart();
  }, [activeDuration]);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  return (
    <>
      <div className="dash-overview">
        <div className="num-holders">
          <ValuesCard
            extraClass={"v-cards"}
            delay={0.5}
            text1="Target funds"
            icon={dolls}
            loading={vLoad}
            bottomComp={
              <>
                <p className="count-value">${overView?.target}</p>
              </>
            }
          />
        </div>
        <div className="num-holders">
          <ValuesCard
            delay={0.5}
            extraClass={"v-cards"}
            text1="Funding progress"
            icon={arc}
            loading={vLoad}
            bottomComp={
              <div>
                <p className="count-value v1">{fundingProgress}%</p>
                <div className="custom-slider">
                  <div
                    className="track"
                    style={{ width: `${fundingProgress}%` }}
                  >
                    <div className="thumb">
                      <div className="thumb-inner"></div>
                    </div>
                  </div>
                </div>
              </div>
            }
          />
        </div>
        <div className="num-holders">
          <ValuesCard
            extraClass={"v-cards"}
            delay={0.5}
            text1="Investor count"
            icon={pfi}
            loading={vLoad}
            bottomComp={
              <>
                <p className="count-value">{overView?.investors} investors</p>
              </>
            }
          />
        </div>
        <div className="num-holders">
          <ValuesCard
            extraClass={"v-cards"}
            delay={0.5}
            text1="Impression"
            icon={impression}
            loading={vLoad}
            bottomComp={
              <>
                <p className="count-value">{overView?.views}</p>
              </>
            }
          />
        </div>
      </div>

      <div className="large-chart">
        <div className="ch-header" ref={dropdownRef}>
          <p>Funds raised</p>
          <div
            className="drop-holder"
            style={{
              width: 200,
              // background: 'red',
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <div
              // ref={dropdownRef}
              className="drop-btn"
              onClick={() => setIsOpen((prev) => !prev)}
            >
              {activeValue}{" "}
              <img
                className={`${isOpen ? "rotate" : ""}`}
                src={dropdownicon}
                alt=""
              />
            </div>
            {isOpen && (
              <div className="duration-drop" style={{ marginTop: 25 }}>
                {chartDurations.map((item, index) => {
                  return (
                    <div
                      className={`duration-text ${
                        activeDuration === item.bDate ? "duration-active" : ""
                      }`}
                      onClick={() => {
                        setActiveDuration(item.bDate);
                        setActiveValue(item.mDate);
                        setIsOpen(false);
                      }}
                    >
                      {item.mDate}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div className="ch-header2">
          <p>${totalFund.toLocaleString()}</p>
        </div>
        <div
          className="main-chart-cont"
          style={{ marginTop: chartData.length === 0 || cload ? 43 : 33 }}
        >
          {cload ? (
            <>
              {cloadArr.map((_: any, ind: number) => (
                <div
                  className={`cload-dash ${
                    ind === cloadArr.length - 1 ? "" : "bottom-26"
                  }`}
                ></div>
              ))}
            </>
          ) : chartData.length === 0  || chartData.every((data: any) => data.amount === 0) ?   (
            <>
              <div className="empty-wrap" style={{height: 400 }}>
                <img src={emptyChart} alt="" />
                <p>No data Available</p>
                <span>Your chart will be here</span>
              </div>
            </>
          ) : (
            // <LineChart backendData={chartData} />
            <LargeChart backendData={chartData} />
          )}
        </div>
      </div>
    </>
  );
}

export default Financails;
