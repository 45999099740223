import React, { useState, useEffect } from "react";
import SideNav from "../../Components/SideNav";
import ProfileNav from "../../Components/ProfileNav";
import { useNavigate } from "react-router-dom";
import chevronB from "../../assets/ion_chevron-back.svg";
import FadeInTop from "../../Components/FadeInTop";
import { ReactComponent as Filter } from "../../assets/filter.svg";
import search from "../../assets/search.svg";
import verse from "../../assets/verse.png";
import vImg from "../../assets/vImg.png";
import emptyList from "../../assets/empty-list.svg";
import pMenu from "../../assets/pMenu.svg";
import DeleteVoucher from "../../Components/DeleteVoucher";
import VoucherDetail from "../../Components/VoucherDetail";
import RepIssues from "../../Components/RepIssues";
import { GetCashBackRequest } from "../../RequestHandlers/Voucher";
import Pagination from "../../Components/Pagination";
import useDebounce from "../../Components/DebounceSearch";

function AllCashbackRequest() {
  const navigate = useNavigate();
  const [searchword, setSearchword] = useState("");
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [sExpanded, setSExpanded] = useState(false);
  const [showDelMenu, setShowDelMenu] = useState(null);
  const [showVoucherDetail, setShowVoucherDetails] = useState(false);
  const [showDelpromt, setShowDelPromt] = useState(false);
  const [cload, setCLoad] = useState(false);
  const [showReportIssue, setShowReportIssue] = useState(false);
  const [cbLoad, setcBLoad] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(9);
  const hLoadArr = [1, 2, 3, 4, 5, 6];
  const [meta, setMeta] = useState<any>([]);
  const debounceWord = useDebounce(searchword, 500);

  const [data, setData] = useState<any>([]);
  const toggleShowVhoucherDetails = (index: number) => {
    // @ts-ignore
    setShowVoucherDetails(true);
  };
  const toggleMenu = (index: number) => {
    // @ts-ignore
    setShowDelMenu(showDelMenu === index ? null : index);
  };
  useEffect(() => {
    const expandTimeout = setTimeout(() => {
      setSExpanded(true);
    }, 500);

    // Return a cleanup function
    return () => clearTimeout(expandTimeout);
  }, []);

  const getCbRequest = async () => {
    setcBLoad(true);
    try {
      const res = await GetCashBackRequest(page, limit, debounceWord);
      console.log(res);
      setData(res.items);
    } catch (error) {
      console.log(error);
    } finally {
      setcBLoad(false);
    }
  };
  
  useEffect(() => {
    getCbRequest();
  }, [debounceWord]);
  useEffect(() => {
    getCbRequest();
  }, []);

  const handleNext = () => {
    if (page < meta.totalPages) setPage((prev) => prev + 1);
  };

  const handlePrevious = () => {
    if (page > 1) setPage((prev) => prev - 1);
  };

  const handlePageClick = (pageNumber: number) => {
    if (pageNumber !== page) setPage(pageNumber);
  };

  return (
    <>
      <div className="dash-body">
        <SideNav activeTab="Vouchers" />
        <div className="content-body">
          <ProfileNav />
          <div className="section-ph">
            <div style={{ width: "100%" }}>
              <button
                onClick={() => {
                  navigate(-1);
                }}
                className="back"
                style={{
                  all: "unset",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  gap: 4,
                  cursor: "pointer",
                }}
              >
                <img src={chevronB} alt="" />
                Back
              </button>
              <p className="ovo ovh">Cashback requests</p>
            </div>
          </div>
          {data.length > 0 && (
            <div className={`mega-search ${sExpanded ? "sExpanded" : ""}`}>
              <FadeInTop delay={1} duration={1} className="search-nim">
                <div className="s-wrap">
                  <img src={search} alt="" />
                  <input
                    type="text"
                    value={searchword}
                    onChange={(e) => {
                      setSearchword(e.target.value);
                    }}
                    placeholder="Search voucher by name"
                  />
                </div>
              </FadeInTop>
              <div className="actions-wrap">
                <FadeInTop delay={1.5} duration={1}>
                  <div
                    className={`filter ${
                      isFilterActive ? "action-active" : ""
                    }`}
                    style={{ margin: 0 }}
                  >
                    <Filter style={{ stroke: "currentColor" }} />
                    Filter
                  </div>
                </FadeInTop>
              </div>
            </div>
          )}
          <div className="v-holder new-hold-height">
            <div
              className="v-cards-wrap v-cards-wrap2"
              style={{ marginTop: 0 }}
            >
              {cbLoad ? (
                <>
                  <div className="v-cards-wrap">
                    {hLoadArr.map(() => (
                      <div
                        className="voucher-card-load"
                        style={{ marginBottom: 16 }}
                      ></div>
                    ))}
                  </div>
                </>
              ) : (
                <>
                  {data.length === 0 ? (
                    <div className="empty-wrap" style={{ height: 300 }}>
                      <img src={emptyList} alt="" />
                      <p>No voucher holder</p>
                      <span>List of voucher holders will appear here</span>
                    </div>
                  ) : (
                    <>
                      {data.map((item: any, index: any) => {
                        return (
                          <div
                            className="voucher-card v-card1"
                            style={{ marginBottom: 16 }}
                          >
                            <div className="cb-wrap1">
                              <img src={item.image} alt="" />
                              <div className="cb-wrap1-b">
                                <h4>{item.addedValue}% added value</h4>
                                <div className="cb-wrap1-alt">
                                  <p className="exp1">Expired</p>
                                  <h4>{item.code}</h4>
                                </div>
                              </div>
                            </div>
                            <div className="cb-item">
                              <h4>Voucher price:</h4>
                              <h4>${item?.value?.toLocaleString()}/Unit</h4>
                            </div>
                            <div className="cb-item">
                              <h4>Niche:</h4>
                              <h4>{item.industry}</h4>
                            </div>
                            <div className="cb-item">
                              <h4>Listed units:</h4>
                              <h4>{item.availableUnits?.toLocaleString()}</h4>
                            </div>
                            <div className="cb-item">
                              <h4>Remaining units:</h4>
                              <h4>
                                {(
                                  item?.availableUnits - item?.unitsSold
                                )?.toLocaleString()}
                              </h4>
                            </div>
                            <button
                              className="vr-btn vr-btn1"
                              onClick={() => {
                                navigate("/VoucherCashback");
                              }}
                            >
                              View requests
                            </button>
                          </div>
                        );
                      })}
                    </>
                  )}
                </>
              )}
            </div>
            {meta.totalPages > 1 && (
              <Pagination
                style={{ marginTop: 16 }}
                meta={meta}
                page={page}
                handleNext={handleNext}
                handlePageClick={handlePageClick}
                handlePrevious={handlePrevious}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AllCashbackRequest;
