import React, { useState, useEffect } from "react";
import SideNav from "../../Components/SideNav";
import ProfileNav from "../../Components/ProfileNav";
import { useNavigate } from "react-router-dom";
import chevronB from "../../assets/ion_chevron-back.svg";
import FadeInTop from "../../Components/FadeInTop";
import { ReactComponent as Filter } from "../../assets/filter.svg";
import emptyList from "../../assets/empty-list.svg";
import arrowLeft from "../../assets/arrow-left.svg";
import arrowRight from "../../assets/b-arrow-right.svg";
import { TableLoader1 } from "../../Components/TableLoaders";
import { formatDate } from "../../Components/formatDate";
import { GetVouchers } from "../../RequestHandlers/Voucher";
import Pagination from "../../Components/Pagination";
function VoucherActivity() {
  const navigate = useNavigate();
  const [showvHistory, setShowVHistory] = useState(false);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [sExpanded, setSExpanded] = useState(false);
  const [showDelMenu, setShowDelMenu] = useState(null);
  const [activeTab, setActiveTab] = useState("All");
  const [filter, setFilter] = useState("");
  const [pHload, setPHLoad] = useState(false);
  const loaderArr = [1, 2, 3, 4];
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [meta, setMeta] = useState<any>([]);

  const tabs = [
    { name: "All", delay: 0.2, option: "" },
    { name: "Published", delay: 0.4, option: "published" },
    { name: "Pending", delay: 0.6, option: "pending" },
    { name: "Rejected", delay: 0.8, option: "rejected" },
    { name: "Expired", delay: 1, option: "expired" },
  ];

  const [data, setData] = useState<any>([]);
  const toggleShowVhoucherDetails = (index: number) => {
    // @ts-ignore
    setShowVoucherDetails(true);
  };
  const toggleMenu = (index: number) => {
    // @ts-ignore
    setShowDelMenu(showDelMenu === index ? null : index);
  };
  useEffect(() => {
    const expandTimeout = setTimeout(() => {
      setSExpanded(true);
    }, 500);
    // Return a cleanup function
    return () => clearTimeout(expandTimeout);
  }, []);
  function capitalizeFirstLetter(word: string): string {
    if (!word) return ""; // Handle empty or null strings
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  const getVouchers = async () => {
    setPHLoad(true);
    try {
      const res = await GetVouchers(page, limit, filter);
      if (res.items) {
        setData(res.items);
      } else {
        setData([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setPHLoad(false);
    }
  };

  const handleNext = () => {
    if (page < meta.totalPages) setPage((prev) => prev + 1);
  };

  const handlePrevious = () => {
    if (page > 1) setPage((prev) => prev - 1);
  };

  const handlePageClick = (pageNumber: number) => {
    if (pageNumber !== page) setPage(pageNumber);
  };
  useEffect(() => {
    getVouchers();
  }, [filter, activeTab]);

  return (
    <>
      <div className="dash-body">
        <SideNav activeTab="Vouchers" />
        <div className="content-body">
          <ProfileNav />
          <div className="section-ph">
            <div style={{ width: "100%" }}>
              <button
                onClick={() => {
                  navigate(-1);
                }}
                className="back"
                style={{
                  all: "unset",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  gap: 4,
                  cursor: "pointer",
                }}
              >
                <img src={chevronB} alt="" />
                Back
              </button>
              <p className="ovo ovh">Voucher Activity</p>
            </div>
          </div>
          <div className="tab-nav">
            {tabs.map((item, index) => {
              return (
                <FadeInTop delay={item.delay} duration={1}>
                  <button
                    className={`${activeTab === item.name ? "activeTab" : ""}`}
                    onClick={() => {
                      setActiveTab(item.name);
                      setFilter(item.option);
                    }}
                  >
                    {item.name}
                  </button>
                </FadeInTop>
              );
            })}
          </div>
          <div className="v-holder new-hold-height">
            {data.length === 0 ? (
              <>
                <div className="empty-wrap empty-wrap-height">
                  <img src={emptyList} alt="" />
                  <p>No voucher available</p>
                  <span>List of vouchers will appear here</span>
                </div>
              </>
            ) : (
              <table className="activity-table" style={{ marginTop: 0 }}>
                <thead>
                  <tr>
                    <th className="th-first">Voucher number</th>
                    <th>Date created</th>
                    <th>Status</th>
                    <th>Reach</th>
                    <th>Total amount invested</th>
                    <th>Investor count</th>
                  </tr>
                </thead>
                {pHload ? (
                  <>
                    <TableLoader1 loaderArr={loaderArr} />
                  </>
                ) : (
                  <tbody>
                    {data.map((item: any, index: any) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div className="tr tr2">{item.code}</div>
                          </td>
                          <td>
                            <div className="tr1 tr2">
                              {formatDate(item.created_at)}
                            </div>
                          </td>
                          <td className={`tr1 tr2`}>
                            <p
                              className={`av-status ${
                                item.status.toLowerCase().includes("pending")
                                  ? "av-pending"
                                  : item?.status
                                      ?.toLowerCase()
                                      ?.includes("rejected")
                                  ? "av-rejected"
                                  : ""
                              }`}
                            >
                              {item.status === "pending"
                                ? "Pending approval"
                                : capitalizeFirstLetter(item?.status)}
                            </p>
                          </td>
                          <td>
                            <div className="tr1 tr2">
                              Seen by {item?.viewCount} people
                            </div>
                          </td>
                          <td>
                            <div className="tr1 tr2">
                              {item?.stats?.totalInvestedAmount}
                            </div>
                          </td>
                          <td>
                            <div className="tr-last1">
                              {item?.stats?.uniqueInvestorsCount}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
            )}
            {meta.totalPages > 1 && (
              <Pagination
                style={{ marginTop: 16 }}
                meta={meta}
                page={page}
                handleNext={handleNext}
                handlePageClick={handlePageClick}
                handlePrevious={handlePrevious}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default VoucherActivity;
