import React, { useState, useEffect } from "react";
import SideNav from "../../Components/SideNav";
import { useNavigate } from "react-router-dom";
import chevronB from "../../assets/ion_chevron-back.svg";
import plus from "../../assets/outline_plus.svg";
import ProfileNav from "../../Components/ProfileNav";
import FadeInTop from "../../Components/FadeInTop";
import search from "../../assets/search.svg";
import { ReactComponent as Filter } from "../../assets/filter.svg";
import arrowLeft from "../../assets/arrow-left.svg";
import arrowRight from "../../assets/b-arrow-right.svg";
import mClose from "../../assets/close.svg";
import copy from "../../assets/copy.svg";
import check from "../../assets/Check.svg";
import verse from "../../assets/verse.png";

function Reward() {
  const navigate = useNavigate();
  const [sExpanded, setSExpanded] = useState(false);
  const [searchword, setSearchword] = useState("");
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [data, setData] = useState([
    {
      name: "First Investment Reward",
      cat: "StabeBlocks rewards",
      condition:
        "Earn this badge by investing in your first project on StableBlocks.",
      claim: 20,
    },
    {
      name: "First Investment Reward",
      cat: "StabeBlocks rewards",
      condition:
        "Earn this badge by investing in your first project on StableBlocks.",
      claim: 20,
    },
    {
      name: "First Investment Reward",
      cat: "StabeBlocks rewards",
      condition:
        "Earn this badge by investing in your first project on StableBlocks.",
      claim: 20,
    },
    {
      name: "First Investment Reward",
      cat: "StabeBlocks rewards",
      condition:
        "Earn this badge by investing in your first project on StableBlocks.",
      claim: 20,
    },
    {
      name: "First Investment Reward",
      cat: "StabeBlocks rewards",
      condition:
        "Earn this badge by investing in your first project on StableBlocks.",
      claim: 20,
    },
    {
      name: "First Investment Reward",
      cat: "StabeBlocks rewards",
      condition:
        "Earn this badge by investing in your first project on StableBlocks.",
      claim: 20,
    },
    {
      name: "First Investment Reward",
      cat: "StabeBlocks rewards",
      condition:
        "Earn this badge by investing in your first project on StableBlocks.",
      claim: 20,
    },
    {
      name: "First Investment Reward",
      cat: "StabeBlocks rewards",
      condition:
        "Earn this badge by investing in your first project on StableBlocks.",
      claim: 20,
    },
    {
      name: "First Investment Reward",
      cat: "StabeBlocks rewards",
      condition:
        "Earn this badge by investing in your first project on StableBlocks.",
      claim: 20,
    },
    {
      name: "First Investment Reward",
      cat: "StabeBlocks rewards",
      condition:
        "Earn this badge by investing in your first project on StableBlocks.",
      claim: 20,
    },
    {
      name: "First Investment Reward",
      cat: "StabeBlocks rewards",
      condition:
        "Earn this badge by investing in your first project on StableBlocks.",
      claim: 20,
    },
  ]);

  useEffect(() => {
    const expandTimeout = setTimeout(() => {
      setSExpanded(true);
    }, 500);

    // Return a cleanup function
    return () => clearTimeout(expandTimeout);
  }, []);

  return (
    <>
      {showDetails && (
        <div className="voucher-detail ">
          <div className="rep-issue req-req r-details">
            <div className="del-promt-nav">
              <p>Reward details</p>
              <button
                style={{ all: "unset", cursor: "pointer" }}
                onClick={() => {
                  setShowDetails(false);
                }}
              >
                <img src={mClose} alt="" />
              </button>
            </div>
            <div className="cash-vDetails">
              <div className="tran-details" style={{ marginTop: 0 }}>
                <div className="tran-details-item">
                  <p>Reward details</p>
                  <p>First Investment Reward</p>
                </div>
                <div className="tran-details-item">
                  <p>Date listed</p>
                  <p>02-07-2024</p>
                </div>
              </div>
              <div className="tran-details-item">
                <p>Reward category</p>
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <p>StabeBlocks rewards</p>
                </div>
              </div>
              <div className="tran-details-item">
                <p>Criteria/condition to earn:</p>
                <p style={{width: 300, textAlign: "right"}}>
                  Earn this badge by investing in your first project on
                  StableBlocks.
                </p>
              </div>
              <div className="tran-details-item">
                <p>Reward to be claimed:</p>
                <p style={{width: 300, textAlign: "right"}}>Exclusive discount on premium products</p>
              </div>
              <div className="tran-details-item">
                <p>Claimed by</p>
                <p>20 investors</p>
              </div>
              <div className="tran-details-item">
                <p>Reward link</p>
               <p style={{width: 300, textAlign: "right"}}>
                  <a
                    href=""
                    style={{ color: "#A7FC00", textDecoration: "none", width: 300,}}
                  >
                    https://examplebusiness.com/rewards/fitness-challenge
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="dash-body">
        <SideNav activeTab="Rewards" />
        <div className="content-body">
          <ProfileNav />
          <div className="section-ph">
            <div className="ph-text-sec">
              <h4>Rewards</h4>
              <p>Create rewards for investors</p>
            </div>
            <div
              className="lnp-btn"
              onClick={() => {
                navigate("/ListReward");
              }}
            >
              <img src={plus} alt="" /> List Rewards
            </div>
          </div>

          {data.length > 0 && (
            <div className={`mega-search ${sExpanded ? "sExpanded" : ""}`}>
              <FadeInTop delay={1} duration={1} className="search-nim">
                <div className="s-wrap">
                  <img src={search} alt="" />
                  <input
                    type="text"
                    value={searchword}
                    onChange={(e) => {
                      setSearchword(e.target.value);
                    }}
                    placeholder="Search voucher by name"
                  />
                </div>
              </FadeInTop>
              <div className="actions-wrap">
                <FadeInTop delay={1.5} duration={1}>
                  <div
                    className={`filter ${
                      isFilterActive ? "action-active" : ""
                    }`}
                    style={{ margin: 0 }}
                  >
                    <Filter style={{ stroke: "currentColor" }} />
                    Filter
                  </div>
                </FadeInTop>
              </div>
            </div>
          )}
          <div
            className="v-holder v-holder2"
            style={{ height: "auto", minHeight: 600 }}
          >
            <table className="activity-table">
              <thead>
                <tr>
                  <th className="th-first">Reward name</th>
                  <th>Reward category</th>
                  <th>Criteria/condition to earn</th>
                  <th>Claimed by</th>
                  <th className="th-action" style={{ paddingLeft: 15 }}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td style={{ minWidth: 150 }}>
                        <div className="tr tr2 f-15">{item.name}</div>
                      </td>
                      <td style={{ minWidth: 150 }}>
                        <div className="tr1 tr2 f-15">{item.cat}</div>
                      </td>
                      <td style={{ width: 350 }}>
                        <div
                          className="tr1 tr2 f-15"
                          style={{ paddingRight: 30 }}
                        >
                          {item.condition}
                        </div>
                      </td>
                      <td style={{ width: 200 }}>
                        <div className="tr1 tr2">{item.claim} investors</div>
                      </td>
                      <td>
                        <div className="tr-last1">
                          <button className="tr-btn">View request</button>
                          <button
                            // className="tr-btn"
                            onClick={() => {
                              setShowDetails(true);
                            }}
                            style={{
                              position: "relative",
                              opacity: 0,
                              cursor: "pointer",
                            }}
                          >
                            View request
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {data.length > 10 && (
              <div className="pagination-cont">
                <button>
                  <img src={arrowLeft} alt="" /> Previous
                </button>
                <div className="pagination-count">
                  <p>1</p>
                  <p>3</p>
                  <p>4</p>
                  <p>...</p>
                  <p>5</p>
                  <p>6</p>
                  <p>7</p>
                </div>
                <button>
                  Next <img src={arrowRight} alt="" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Reward;
