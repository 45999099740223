import HeaderBar from "../Components/HeaderBar";
import ProfileNav from "../Components/ProfileNav";
import SideNav from "../Components/SideNav";
import trophyIcon from "../assets/trophy-circle.svg";
import infoIcon from "../assets/info-cricle-with-bg.svg";
import medalIcon from "../assets/medal-circle.svg";
import starIcon from "../assets/star-medal.svg";
import giftIcon from "../assets/gift-circle.svg";
import documentCheck from "../assets/document-check.svg";
import "../css/style.css";
import "../css/notification/style.css";
import NotificationTabHeader from "../Components/notifications/notification-tab-header";
import { useEffect, useState } from "react";
import { GetNotifications } from "../RequestHandlers/Notifications";
import { timeConverter } from "../utils/time-converter";
import FadeInAnimation from "../Components/FadeIn";
interface ListProps {
  title: string;
  description: string;
  time: string;
  icon: string;
}
const notification_data = [
  {
    title: "You have a new investor.",
    desrciption: "Ene Anna invested $5,000 in the Cityscape Living project.",
    icon: trophyIcon,
    time: "12 mins ago",
  },
  {
    title: "We have just released a new update",
    desrciption: "Check out the new features on the dashboard.",
    icon: infoIcon,
    time: "12 mins ago",
  },
  {
    title: "Your project has been listed successfully ",
    desrciption: "Cityscape Living project has been listed!. Great job!.",
    icon: medalIcon,
    time: "12 mins ago",
  },
  {
    title: "Your project has been spotlighted successfully ",
    desrciption: "Cityscape Living project has been spotlighted!. Welldone!",
    icon: starIcon,
    time: "12 mins ago",
  },
  {
    title: "Your voucher has been created successfully ",
    desrciption:
      "Your voucher 20% off all massages has been successfully created",
    icon: giftIcon,
    time: " 01-10-2024  7:24am",
  },
  {
    title: "Your profile has been updated",
    desrciption:
      "Your password has been successfully updated. If you did not make this change, please contact support immediately.",
    icon: giftIcon,
    time: " 01-10-2024  7:24am",
  },
  {
    title: "Your voucher has been created successfully ",
    desrciption:
      "Your voucher 20% off all massages has been successfully created",
    icon: giftIcon,
    time: " 01-10-2024  7:24am",
  },

  {
    title: "Your voucher has been created successfully ",
    desrciption:
      "Your voucher 20% off all massages has been successfully created",
    icon: giftIcon,
    time: " 01-10-2024  7:24am",
  },
];
const NotificationScreen = () => {
  const [activeOption, setActiveOption] = useState("all");
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const getNotificationsHandler = async () => {
    try {
      const response = await GetNotifications();
      if (response) {
        setNotifications(response?.items);
        setFilteredNotifications(response?.items);
      }
    } catch (error) {
      console.log("Error occured while fetching notifications", error);
    }
  };

  useEffect(() => {
    getNotificationsHandler();
  }, []);
  useEffect(() => {
    // Perform filtering based on the active option
    if (activeOption === "unread") {
      const unreadNotifications = notifications.filter(
        (item: any) => !item.isSeen
      );
      console.log("unread", unreadNotifications);
      setFilteredNotifications(unreadNotifications);
    } else if (activeOption === "read") {
      const readNotifications = notifications.filter(
        (item: any) => item.isSeen
      );
      console.log("read", readNotifications);
      setFilteredNotifications(readNotifications);
    } else {
      // Show all notifications
      setFilteredNotifications(notifications);
    }
  }, [activeOption]);

  return (
    <>
      <div
        style={{
          paddingBottom: 119,
        }}
        className="dash-body"
      >
        <SideNav activeTab="Dashboard" />
        <div className="content-body">
          <ProfileNav />
          <HeaderBar />
          <div
            style={{
              width: "100%",
              marginTop: 20,
            }}
          >
            <h1 className="notification-header-title">Notifications</h1>
          </div>

          <div className="notification-tab-container">
            <NotificationTabHeader
              activeOption={activeOption}
              setActiveOption={setActiveOption}
            />

            <div
              style={{
                marginTop: 5,
              }}
              className="notification-items-view"
            >
              {filteredNotifications?.length > 0 ? (
                <>
                  {filteredNotifications?.map(
                    ({ title, icon, body, created_at }, index) => (
                      <FadeInAnimation delay={index * 0.1} duration={0.5}>
                        <NotificationList
                          key={index}
                          time={created_at}
                          title={title}
                          description={body}
                          icon={icon}
                        />
                      </FadeInAnimation>
                    )
                  )}
                </>
              ) : (
                <div className="no-notification-view">
                  <img
                    src={documentCheck}
                    style={{
                      width: 50,
                      height: 50,
                    }}
                  />
                  <p
                    className="no-notification-title"
                    style={{
                      fontSize: 18,
                    }}
                  >
                    No notifications yet
                  </p>
                  <p
                    className="no-notification-description"
                    style={{
                      fontSize: 14,
                    }}
                  >
                    Your notifications will appear here.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationScreen;

const NotificationList = ({ title, time, description, icon }: ListProps) => {
  return (
    <>
      <div
        style={{
          width: "100%",
          borderBottom: "1px solid #1D1D1D",
        }}
      >
        <div className="notification-items-container">
          <div className="notification-items-icon-view">
            <img src={icon} />
          </div>
          <div className="notification-info-view">
            <div
              style={{
                width: "100%",
              }}
            >
              <p className="notification-info-title">{title}</p>
            </div>
            <div className="notification-items-action-view">
              <p className="notification-info-description">{description}</p>
              <p className="notification-time">{timeConverter(time)}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
