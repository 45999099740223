import { useState } from "react";

interface FileUpload {
  file: File | any; // Use the File object directly for web
}

interface UseCloudinaryUploadReturn {
  uploadFile: (file: FileUpload) => Promise<void>;
  url: string | null;
  loading: boolean;
  error: string | null;
  success: boolean;
}

const cloud_name = "daqjecxhy";
const cloud_key = "868378813212551";
const preset_name = "stable_blocks";

const useCloudinaryUpload = (): UseCloudinaryUploadReturn => {
  const [url, setUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);

  const uploadFile = async (fileUpload: FileUpload) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    const formData = new FormData();
    formData.append("file", fileUpload.file); // Use the File object
    formData.append("upload_preset", preset_name);

    try {
      const response = await fetch(
        `https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`,
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error?.message || "Failed to upload");
      }

      setUrl(data.secure_url); // Cloudinary returns the URL in 'secure_url'
      setSuccess(true);
      return data.secure_url;
    } catch (err: any) {
      setError(err.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  return { uploadFile, url, loading, error, success };
};

export default useCloudinaryUpload;
