import React, { useState, useRef, useEffect } from "react";
import verse from "../assets/verse.png";
import close from "../assets/close-circle.svg";
import ticket from "../assets/ticket.svg";
import calender from "../assets/date.svg";
import notCalender from "../assets/notCalender.svg";
import dropdownicon from "../assets/icon-park_down.svg";
import emptyChart from "../assets/empty-chart.svg";
import LineChart from "./LineChart";
import {
  GetVoucherSaleChart,
  GetVouchersByID,
} from "../RequestHandlers/Voucher";
import { formatDate3 } from "./formatDate";
interface PProps {
  onClose?: any;
  cload?: boolean;
  showReportIssue?: any;
  onDelete?: any;
  id?: any;
}
function VoucherDetail({ onClose, showReportIssue, onDelete, id }: PProps) {
  const [isExhusted, setIsExhusted] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [activeValue, setActiveValue] = useState("Last 14 days");
  const [activeDuration, setActiveDuration] = useState("last-14-days");
  const [chartData, setChartData] = useState<any>([]);
  const cloadArr = [1, 2, 3, 4];
  const [details, setDetails] = useState<any>([]);
  const [cload, setCload] = useState(false);
  const [disable, setDisable] = useState(false)

  const chartDurations = [
    { mDate: "Last 14 days", bDate: "last-14-days" },
    { mDate: "Last 30 days", bDate: "last-30-days" },
    { mDate: "Last 60 days", bDate: "last-60-days" },
    { mDate: "Last 90 days", bDate: "last-90-days" },
    { mDate: "Last 120 days", bDate: "last-120-days" },
  ];
  function calculateEndDate(
    startDateString: string,
    durationInDays: number
  ): string {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    // Parse the start date
    const startDate = new Date(startDateString);
    // Add the duration to the start date
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + durationInDays);
    // Format the end date
    const month = months[endDate.getMonth()];
    const day = endDate.getDate().toString().padStart(2, "0");
    const year = endDate.getFullYear();

    return `${month} ${day}, ${year}`;
  }
  const allAmountsAreZero = chartData.every((item: any) =>
    item.data.every((entry: any) => entry.amount === 0)
  );
  const getVoucher = async () => {
    try {
      const res = await GetVouchersByID(id);
      setDetails(res);
      if (res.unitsSold === 0) {
        setDisable(false)
      } else {
        setIsExhusted(true);
      }
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  const getVoucherChart = async () => {
    try {
      setCload(true);
      const res = await GetVoucherSaleChart(id, activeDuration);
      setChartData(res.projectCharts);
      console.log(res.projectCharts);
    } catch (error) {
      console.log(error);
    } finally {
      setCload(false);
    }
  };
  useEffect(() => {
    getVoucherChart();
  }, [activeDuration]);

  useEffect(() => {
    getVoucher();
    getVoucherChart();
  }, []);
  return (
    <div className="details-card">
      <div className="d-card-nav">
        <div className="d-card-wrap1">
          <img src={details.image} alt="" style={{ objectFit: "cover" }} />
          <p>{details?.addedValue}% added value</p>
          <div className={`pr-status  ${isExhusted ? "exh" : ""}`}>
           {isExhusted ? "Exhusted": "Published"}
          </div>
          <div className="pr-num">{details.code}</div>
        </div>
        <button>
          <img src={close} alt="" onClick={onClose} />
        </button>
      </div>
      <div className="dd-details-cont">
        <h4>Voucher details</h4>
        <div className="dd-details-item">
          <div className="dd-item">
            <img src={ticket} alt="" />
            <div className="dd-item-wrap">
              <h4>Voucher Price</h4>
              <p>${details?.value?.toLocaleString() || "..."} / Unit</p>
            </div>
          </div>
          <div className="dd-item">
            <img src={calender} alt="" />
            <div className="dd-item-wrap">
              <h4>Start Date</h4>
              <p style={{ color: "#C3B2F7" }}>
                {formatDate3(details.created_at)}
              </p>
            </div>
          </div>
          <div className="dd-item">
            <img src={notCalender} alt="" />
            <div className="dd-item-wrap">
              <h4>End Date</h4>
              <p style={{ color: "#BA2543" }}>
                {calculateEndDate(details.created_at, details.duration) || "..."}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="chart-cont">
        <div className="ch-header">
          <p>Impressions</p>
          <div
            className="drop-holder"
            style={{
              width: 200,
              // background: 'red',
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              position: "relative",
            }}
          >
            <div
              ref={dropdownRef}
              className="drop-btn"
              onClick={() => setIsOpen((prev) => !prev)}
            >
              {activeValue}{" "}
              <img
                className={`${isOpen ? "rotate" : ""}`}
                src={dropdownicon}
                alt=""
              />
            </div>
            {isOpen && (
              <div className="duration-drop">
                {chartDurations.map((item, index) => {
                  return (
                    <div
                      className={`duration-text ${
                        activeDuration === item.bDate ? "duration-active" : ""
                      }`}
                      onClick={() => {
                        setActiveDuration(item.bDate);
                        setActiveValue(item.mDate);
                        setIsOpen(false);
                      }}
                    >
                      {item.mDate}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        {chartData.length === 0 ? (
          <></>
        ) : (
          <div className="ch-header2">
            <p>{details?.viewCount?.toLocaleString()} views</p>
          </div>
        )}
        <div
          className="main-chart-cont"
          style={{
            marginTop: chartData.length === 0 || cload ? 43 : 33,
          }}
        >
          {cload ? (
            <>
              {cloadArr.map((_: any, ind: number) => (
                <div
                  className={`cload-dash ${
                    ind === cloadArr.length - 1 ? "" : "bottom-26"
                  }`}
                ></div>
              ))}
            </>
          ) : chartData.length === 0 || allAmountsAreZero ? (
            <>
              <div className="empty-wrap" style={{ marginTop: 24 }}>
                <img src={emptyChart} alt="" />
                <p>No data Available</p>
                <span>Your chart will be here</span>
              </div>
            </>
          ) : (
            <LineChart backendData={chartData} backgroundDisabld={true} />
          )}
        </div>
      </div>
      <div className="dd-btns">
        <button onClick={showReportIssue}>Report issue</button>
        <button
          disabled={disable}
          style={{ backgroundColor: disable ? "#dd7489ec" : "" }}
          onClick={onDelete}
        >
          Delete voucher
        </button>
      </div>
    </div>
  );
}

export default VoucherDetail;
