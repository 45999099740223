export class RequestHandler {
    constructor() {}
    private BASE_URL = "https://stableblocks-staging-927c39ef717b.herokuapp.com/";
  
    public get(
      path: string,
      token?: string,
      functions?: Array<(data: any) => void>
    ) {
      fetch(`${this.BASE_URL}${path}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (functions && functions.length > 0) {
            functions.forEach((func) => func(data));
          } else {
            return data;
          }
        });
    }
  
    public post(
      path: string,
      body: object,
      token?: string,
      functions?: Array<(data: any) => void>
    ) {
      fetch(`${this.BASE_URL}${path}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      })
        .then((res) => res.json())
        .then((data) => {
          if (functions && functions.length > 0) {
            functions.forEach((func) => func(data));
          } else {
            return data;
          }
        });
    }
  
    public put(
      path: string,
      body: object,
      token?: string,
      functions?: Array<(data: any) => void>
    ) {
      fetch(`${this.BASE_URL}${path}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      })
        .then((res) => res.json())
        .then((data) => {
          if (functions && functions.length > 0) {
            functions.forEach((func) => func(data));
          } else {
            return data;
          }
        });
    }
  
    public patch(
      path: string,
      body: object,
      token?: string,
      functions?: Array<(data: any) => void>
    ) {
      fetch(`${this.BASE_URL}${path}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      })
        .then((res) => res.json())
        .then((data) => {
          if (functions && functions.length > 0) {
            functions.forEach((func) => func(data));
          } else {
            return data;
          }
        });
    }
  
    public delete(
      path: string,
      body: object,
      token?: string,
      functions?: Array<(data: any) => void>
    ) {
      fetch(`${this.BASE_URL}${path}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      })
        .then((res) => res.json())
        .then((data) => {
          if (functions && functions.length > 0) {
            functions.forEach((func) => func(data));
          } else {
            return data;
          }
        });
    }
  }
  