import React, { useState } from "react";
import SideNav from "../../Components/SideNav";
import ProfileNav from "../../Components/ProfileNav";
import supportFrame from "../../assets/sf.webp";
import whatsApp from "../../assets/whatsApp.svg";
import phone from "../../assets/phone.svg";
import mail from "../../assets/mail.svg";
import dropDown from "../../assets/chev-down.svg";

function Support() {
  const [activeIndex, setActiveIndex] = useState(null);
  const toggleAnswer = (index: any) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  const faqs = [
    {
      title: "What types of projects can be listed on the platform?",
      body: "",
    },
    {
      title: "How do I create a new project?",
      body: `To create a new project, first ensure you're logged into your account. From your dashboard, navigate to the "Add New Project" button, which will guide you through a step-by-step process. You’ll be required to input essential details such as the project name, industry, the funding goal you aim to reach, and the available funding rounds. Additionally, you can upload supporting documents like a business plan, project overview, and any relevant visuals to enhance the presentation of your project to potential investors.`,
    },
    {
      title: "What happens if my project doesn’t get fully funded?",
      body: "",
    },
    {
      title: "What is the expected ROI for investors?",
      body: "",
    },
    {
      title: "Can I unpublish my project?",
    },
  ];
  return (
    <div className="dash-body">
      <SideNav activeTab="Support" />
      <div className="content-body">
        <ProfileNav />
        <div className="section-ph">
          <div className="ph-text-sec">
            <h4>Support</h4>
            <p>Need help? We're here for you.</p>
          </div>
        </div>
        <div className="sup-frame">
          <div className="supports">
            <button>
              <img src={whatsApp} alt="" />
            </button>
            <button>
              <img src={phone} alt="" />
            </button>
            <button>
              <img src={mail} alt="" />
            </button>
          </div>
          <div className="support-text">
            <h4>Stay Connected</h4>
            <p>Contact us via WhatsApp, mobile or email.</p>
          </div>
        </div>
        <div className="content-cont">
          <p className="ov">FAQs</p>
          {faqs.map((item, index) => {
            return (
              <div
                className="faq-item"
                onClick={() => {
                  toggleAnswer(index);
                }}
              >
                <div
                  className="faq-header"
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer"
                  }}
                >
                  <h4>{item.title}</h4>
                  <img
                    src={dropDown}
                    alt=""
                    className={`${activeIndex === index ? "faq-active" : ""}`}
                  />
                </div>
                {activeIndex === index && <p>{item.body}</p>}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Support;
