import React, { useEffect, useRef, useState } from "react";
import SideNav from "../../Components/SideNav";
import ProfileNav from "../../Components/ProfileNav";
import { useLocation, useNavigate } from "react-router-dom";
import ProgressRing from "../../Components/ProgressRing";
import chevBack from "../../assets/ion_chevron-back.svg";
import Input from "../../Components/Input";
import { useFileUpload } from "../../context/uploadFile";
import chevDown from "../../assets/chev-down.svg";
import NumberMonthDropdown from "../../Components/NumberMonthDropdown";
import plus from "../../assets/plus.svg";
import colon from "../../assets/colon.svg";
import Cookies from "js-cookie";
import Toast, { handleToast } from "../../Components/Toast";
import { CreateVoucher } from "../../RequestHandlers/Voucher";
interface Row {
  months: string;
  roi: string;
}
function CreateNewVoucher3() {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state.data || {};
  const [tName, setTName] = useState("");
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [activeIndustry, setActiveIndustry] = useState<any>(null);
  const [dropActive, setDropActive] = useState(false);
  const [industries, setIndustries] = useState<any>(null);
  const [aUnit, setAUnit] = useState("");
  const [unit, setUnit] = useState("");
  const [showFd, setShowFD] = useState(false);
  const [activeFd, setActiveFd] = useState<any>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFocused1, setIsFocused1] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [product, setProduct] = useState([""]);
  const [services, setServices] = useState([""]);
  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const formatWithComma = (value: string | number): string => {
    if (!value) return "";
    return new Intl.NumberFormat("en-US").format(Number(value));
  };
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const cNumRegex =
    /^\+?(\d{1,3})?[-. (]*(\d{1,4})[-. )]*(\d{1,4})[-. ]*(\d{1,9})$/;

  const handleFieldChange = (field: string, value: string) => {
    setErrors((prevErrors: any) => {
      const updatedErrors = { ...prevErrors };
      if (updatedErrors[field]) {
        delete updatedErrors[field];
      }
      return updatedErrors;
    });
  };
  const handleAddPr = () => {
    setProduct([...product, ""]);
  };
  const handleAddSr = () => {
    setServices([...services, ""]);
  };
  const handleProduct = (index: number, value: string) => {
    const updatedSkills = [...product];
    updatedSkills[index] = value;
    setProduct(updatedSkills);
  };
  const handleServices = (index: number, value: string) => {
    const updatedSkills = [...services];
    updatedSkills[index] = value;
    setServices(updatedSkills);
  };

  const saveToDraft = (credentials: any) => {
    try {
      // Get the current value from localStorage
      const existingData = localStorage.getItem("vDraft");
      const parsedData = existingData ? JSON.parse(existingData) : {};

      // Merge the new credentials with the existing data
      const updatedData = {
        ...parsedData,
        ...credentials, // This will overwrite any matching keys
      };

      // Save the updated data back to localStorage
      localStorage.setItem("vDraft", JSON.stringify(updatedData));

      // Notify the user
      handleToast("Saved to draft", "success");
      console.log(updatedData);
    } catch (err) {
      console.error("Error updating credentials:", err);
      handleToast("Error saving to draft", "error");
    }
  };

  const getDraftDetails = () => {
    try {
      // Retrieve the data from localStorage
      const savedData = localStorage.getItem("vDraft");

      if (savedData) {
        const credentials = JSON.parse(savedData);
        console.log("credentails", credentials);

        if (credentials.email) {
          // You can add specific logic here if needed
          setActiveIndustry(credentials.bussinessType);
          setServices(credentials.services);
          setProduct(credentials.product);
          setPhone(credentials.phone);
          setEmail(credentials.email);
        } else {
          // Handle the case where `addedvalue` does not exist
        }

        return credentials; // Return the parsed credentials
      } else {
        console.log("No draft found.");
        return null;
      }
    } catch (err) {
      console.error("Error retrieving draft details:", err);
      return null;
    }
  };

  const createVoucher = async () => {
    const videoLinks = data.videos.map((video: any) => video.link);
    const imageLinks = data.images.map((image: any) => image.link);
    const allLinks = videoLinks.concat(imageLinks);
    let newservices: any;
    let newProducts: any;
    if (product.every((product) => product === "")) {
      newProducts = [];
    } else {
      newProducts = product.filter((product) => product !== "");
    }
    if (services.every((service) => service === "")) {
      newservices = [];
    } else {
      newservices = services.filter((service) => service !== "");
    }
    const buisnessType = activeIndustry.includes("Product and service")
      ? "product-and-service"
      : activeIndustry.toLowerCase();
    const addedVal = data.addedvalue[0].roi;
    const body = {
      name: data.name,
      image: data.img,
      description: data.aboutTalent,
      gallery: allLinks,
      benefits: newservices,
      products: newProducts,
      value: Number(data.vnp),
      duration: data.activeVoucherValidity.value,
      availableUnits: Number(data.au),
      ceoName: data.ceoName,
      ceoLinkedin: data.ceoLinkdin,
      companyWebsite: data.cwu,
      businessLocation: data.bl,
      businessEmail: email,
      businessPhone: phone,
      industry: data.activeIndustry.name,
      currency: "usd",
      buisnessType: buisnessType,
      addedValue: Number(addedVal),
    };
    setLoading(true);
    console.log("body", body);
    try {
      const res = await CreateVoucher(body);
      if (res.code) {
        navigate("/VoucherCreated");
      } else {
        handleToast(res.message, "success");
      }
      console.log(res);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleFormSubmit = (e: React.FormEvent, type: string) => {
    e.preventDefault();
    console.log("Form submission attempted");

    const newErrors: Record<string, string> = {};

    // Validate at least one of product or service
    const isProductValid = product.some((item) => item.trim() !== "");
    const isServiceValid = services.some((item) => item.trim() !== "");

    if (!isProductValid && !isServiceValid) {
      newErrors.productOrService =
        "At least one product or service is required";
      handleToast("A product or service is required", "error");
    }
    // Validate phone
    if (phone.trim() === "") {
      newErrors.phone = "Field is required";
    } else if (!cNumRegex.test(phone)) {
      newErrors.phone = "Invalid contact number";
    }

    // Validate email
    if (email.trim() === "") {
      newErrors.email = "Field is required";
    } else if (!emailRegex.test(email)) {
      newErrors.email = "Invalid email address";
    }

    // Validate active industry
    if (activeIndustry === null) {
      newErrors.activeIndustry = "Field is required";
    }

    // If errors exist, set them and stop submission
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // If no errors, proceed with form submission
    console.log("Form submitted successfully:");

    // Prepare data object for submission
    const data = {
      product: product,
      services: services,
      phone: phone,
      email: email,
      bussinessType: activeIndustry,
    };

    if (type === "draft") {
      saveToDraft(data);
    } else {
      createVoucher();
      // navigate("/VoucherCreated");
    }
  };

  useEffect(() => {
    console.log(data);
    getDraftDetails();

    setIndustries(["Product-based", "Service-based", "Product and service"]);
  }, []);
  // const handleSubmitForm = () => {
  //   navigate("/VoucherCreated");
  // };
  return (
    <div className="dash-body">
      <Toast />
      <SideNav activeTab="Vouchers" />
      <div className="content-body">
        <ProfileNav />
        <div
          className="c-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src={chevBack} alt="" />
          <p>Back</p>
        </div>
        <div className="sec-header">
          <p>Create New Voucher</p>
          <ProgressRing
            radius={20}
            stroke={4}
            progress={100}
            text={"3/3"}
            backgroundColor="#111900"
          />
        </div>
        <div className="list-content-holder">
          <div className="content-cont">
            <div className="option-holders" style={{ marginTop: 0 }}>
              <div className="dropdown-holder" style={{ width: "100%" }}>
                <div
                  className={`reg-file-cont drop-down ${
                    activeIndustry !== null ? "focused" : ""
                  } ${errors.activeIndustry ? "img-cont-error" : ""}`}
                  onClick={() => {
                    setDropActive(!dropActive);
                  }}
                >
                  <label className="floating-label">Business Type</label>
                  <div className="active-ind">{activeIndustry}</div>
                  <div className="chev">
                    <img
                      src={chevDown}
                      alt=""
                      className={`${dropActive ? "rotate" : ""}`}
                    />
                  </div>
                </div>
                {errors.activeIndustry && (
                  <p className="error-text">{errors.activeIndustry}</p>
                )}
                {dropActive && (
                  <div className="in-drop-down">
                    {industries.map((item: any, index: number) => {
                      return (
                        <div
                          key={index}
                          className={`ind ${
                            item === activeIndustry ? "ind-active" : ""
                          }`}
                          onClick={() => {
                            setActiveIndustry(item);
                            setDropActive(false);
                            handleFieldChange("activeIndustry", item);
                          }}
                        >
                          {item}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="content-cont">
            <p className="ov">Services </p>
            <div className="rf" style={{ marginTop: 16 }}>
              {services.map((item, index) => {
                return (
                  <div className="tt-cont">
                    <Input
                      contClass="list-input-cont"
                      extraClass="list-input"
                      label="Service name"
                      contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                      value={item}
                      onChange={(e) => {
                        handleServices(index, e.target.value);
                        handleFieldChange(`sr${index}`, e.target.value);
                      }}
                      error={errors.productOrService}
                      // errorText={errors[`sr${index}`]}
                    />
                  </div>
                );
              })}
              <div className="dash"></div>
              <div className="inc-btn-cont">
                <div
                  className="add-btn"
                  onClick={() => {
                    handleAddSr();
                  }}
                >
                  <img src={plus} alt="" /> Add more
                </div>
              </div>
            </div>
          </div>
          <div className="content-cont">
            <p className="ov">Products</p>
            <div className="rf" style={{ marginTop: 16 }}>
              {product.map((item, index) => {
                return (
                  <div className="tt-cont">
                    <Input
                      contClass="list-input-cont"
                      extraClass="list-input"
                      label="Product name"
                      contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                      value={item}
                      onChange={(e) => {
                        handleProduct(index, e.target.value);
                        handleFieldChange(`pr${index}`, e.target.value);
                      }}
                      error={errors.productOrService}
                      // errorText={errors[`pr${index}`]}
                    />
                  </div>
                );
              })}
              <div className="dash"></div>
              <div className="inc-btn-cont">
                <div
                  className="add-btn"
                  onClick={() => {
                    handleAddPr();
                  }}
                >
                  <img src={plus} alt="" /> Add more
                </div>
              </div>
            </div>
          </div>
          <div className="content-cont">
            <p className="ov">Company contact</p>
            <div className="option-holders">
              <div className="input-holder">
                <Input
                  contClass="list-input-cont"
                  extraClass="list-input"
                  label="Email Address"
                  contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    handleFieldChange("email", e.target.value);
                  }}
                  error={errors.email}
                  errorText={errors.email}
                />
              </div>
              <div className="input-holder">
                <Input
                  contClass="list-input-cont"
                  extraClass="list-input"
                  label="Phone Number"
                  contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                    handleFieldChange("phone", e.target.value);
                  }}
                  error={errors.phone}
                  errorText={errors.phone}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="std-cont">
          <div
            className="std-btn"
            onClick={(e) => {
              handleFormSubmit(e, "draft");
            }}
          >
            Save to draft
          </div>
          <button
            className={`c-btn ${loading ? "c-disabled" : ""}`}
            disabled={loading}
            onClick={(e) => {
              handleFormSubmit(e, "submit");
            }}
          >
            {loading ? (
              <div className="dot-loader">
                <span className="dot"></span>
                <span className="dot"></span>
                <span className="dot"></span>
              </div>
            ) : (
              "Publish"
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default CreateNewVoucher3;
