import React, { useState, useEffect } from "react";
import HeaderBar from "../Components/HeaderBar";
import { useNavigate } from "react-router-dom";
import Input from "../Components/Input";
import FadeInAnimation from "../Components/FadeIn";
import checkActive from "../assets/checkActive.svg";
import checkBox from "../assets/Checkbox.svg";
import SecBtn from "../Components/SecBtn";

export default function SignUpScreen() {
  const navigate = useNavigate();

  const [isCheckActive, setIsCheckActive] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

  const validateField = (fieldName: string, value: string) => {
    switch (fieldName) {
      case "firstName":
        return value.trim() === "" ? "First Name is required" : "";
      case "lastName":
        return value.trim() === "" ? "Last Name is required" : "";
      case "email":
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (value.trim() === "") return "Email is required";
        return !emailRegex.test(value) ? "Invalid email address" : "";
      case "phone":
        const phoneRegex = /^\d{10,15}$/;
        if (value.trim() === "") return "Phone number is required";
        return !phoneRegex.test(value) ? "Invalid phone number" : "";
      default:
        return "";
    }
  };

  const handleInputChange = (fieldName: string, value: string) => {
    switch (fieldName) {
      case "firstName":
        setFirstName(value);
        break;
      case "lastName":
        setLastName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "phone":
        setPhone(value);
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: validateField(fieldName, value),
    }));
  };
  // const handleValidation = () => {
  //   const newErrors = {
  //     firstName: validateField("firstName", firstName),
  //     lastName: validateField("lastName", lastName),
  //     email: validateField("email", email),
  //     phone: validateField("phone", phone),
  //   };
  //   setErrors(newErrors);
  //   return !Object.values(newErrors).some((error) => error !== "");
  // };
  const isFormValid = () => {
    return (
      Object.values(errors).every((error) => error === "") &&
      firstName &&
      lastName &&
      email &&
      phone &&
      isCheckActive
    );
  };
  const handleSubmit = async () => {
    if (isFormValid()) {
      const data1 = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
      };
      navigate("/VerifyEmailScreen", { state: { data1 } });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="tk-reg-body">
      <HeaderBar
        onclick={() => {
          navigate(-1);
        }}
        showProgress={true}
        progress={33.3}
        progressText="1/3"
      />
      <div className="pageCont">
        <FadeInAnimation delay={0.3} duration={1}>
          <h4 className="pageH4">Create an account</h4>
        </FadeInAnimation>
        <FadeInAnimation delay={0.6} duration={1}>
          <p className="pageP">
            This should only take a few minutes. Please note that you can only
            list a project
            <br />
            you’ve already tokenized with us.
          </p>
        </FadeInAnimation>
        <div className="inputCont1 mTop56">
          <FadeInAnimation delay={0.9} duration={1}>
            <Input
              type="text"
              label="First Name"
              value={firstName}
              onChange={(e) => handleInputChange("firstName", e.target.value)}
              error={errors.firstName ? true : false}
              errorText={errors.firstName}
            />
          </FadeInAnimation>
        </div>
        <div className="inputCont1">
          <FadeInAnimation delay={1.2} duration={1}>
            <Input
              type="text"
              label="Last Name"
              value={lastName}
              onChange={(e) => handleInputChange("lastName", e.target.value)}
              error={errors.lastName ? true : false}
              errorText={errors.lastName}
            />
          </FadeInAnimation>
        </div>
        <div className="inputCont1">
          <FadeInAnimation delay={1.5} duration={1}>
            <Input
              type="text"
              label="Email Address"
              value={email}
              onChange={(e) => handleInputChange("email", e.target.value)}
              error={errors.email ? true : false}
              errorText={errors.email}
            />
          </FadeInAnimation>
        </div>
        <div className="inputCont1">
          <FadeInAnimation delay={1.8} duration={1}>
            <Input
              type="text"
              label="Phone Number"
              value={phone}
              onChange={(e) => handleInputChange("phone", e.target.value)}
              error={errors.phone ? true : false}
              errorText={errors.phone}
            />
          </FadeInAnimation>
        </div>

        <div className="bottomCont">
          <FadeInAnimation delay={2.5} duration={1}>
            <div className="checkCont">
              <div
                className="checkBtn"
                onClick={() => {
                  setIsCheckActive(!isCheckActive);
                }}
              >
                <img src={isCheckActive ? checkActive : checkBox} alt="" />
              </div>
              <p>
                By checking this button, you agree to our{" "}
                <span className="t-span">Terms & Conditions</span> and
                <br /> <span className="t-span">Privacy Statement</span>
              </p>
            </div>
            <SecBtn
              text="Continue"
              onPress={handleSubmit}
              disabled={!isFormValid()}
            />
            <p className="linkText">
              Not tokenized yet?
              <span
                className="link"
                onClick={() => {
                  console.log("clicked");
                  navigate("/tokenizewithus");
                }}
              >
                {" "}
                Tokenize with us
              </span>
            </p>
          </FadeInAnimation>
        </div>
      </div>
    </div>
  );
}
