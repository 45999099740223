import React, { CSSProperties, useMemo } from "react";
import arrowLeft from "../assets/arrow-left.svg";
import arrowRight from "../assets/b-arrow-right.svg";

interface PProps {
  meta: any;
  page: number;
  handlePrevious?: any;
  handleNext?: any;
  handlePageClick?: any;
  style?:CSSProperties
}
function Pagination({
  meta,
  page,
  handlePageClick,
  handleNext,
  handlePrevious,
  style
}: PProps) {
  const paginationPages = useMemo(() => {
    const totalPages = meta.totalPages || 1;
    const pages = [];
    const isNearStart = page <= 3;
    const isNearEnd = page >= totalPages - 3;

    if (isNearStart) {
      for (let i = 1; i <= Math.min(3, totalPages); i++) {
        pages.push(i);
      }
      if (totalPages > 6) pages.push("...", totalPages - 1, totalPages);
    } else if (isNearEnd) {
      pages.push(1, "...");
      for (let i = totalPages - 4; i <= totalPages; i++) {
        if (i > 0) pages.push(i);
      }
    } else {
      pages.push(1, "...");
      for (let i = page - 1; i <= page + 1; i++) {
        pages.push(i);
      }
      pages.push("...", totalPages);
    }

    return pages;
  }, [page, meta.totalPages]);
  return (
    <div className="pagination-cont" style={style}>
      <button
        onClick={handlePrevious}
        disabled={page === 1}
        aria-label="Previous Page"
        className={page === 1 ? "disabled" : ""}
      >
        <img src={arrowLeft} alt="Previous" /> Previous
      </button>
      <div className="pagination-count">
        {paginationPages.map((p, index) =>
          typeof p === "number" ? (
            <p
              key={index}
              onClick={() => handlePageClick(p)}
              className={page === p ? "pag-active" : ""}
              style={{
                cursor: "pointer",
                color: page === p ? "#fff" : "",
              }}
            >
              {p}
            </p>
          ) : (
            <p key={index} className="ellipsis">
              {p}
            </p>
          )
        )}
      </div>
      <button
        onClick={handleNext}
        disabled={page === meta.totalPages}
        aria-label="Next Page"
        className={page === meta.totalPages ? "disabled" : ""}
      >
        Next <img src={arrowRight} alt="Next" />
      </button>
    </div>
  );
}

export default Pagination;
