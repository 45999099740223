import React, { useState, useEffect } from "react";
import SideNav from "../../Components/SideNav";
import ProfileNav from "../../Components/ProfileNav";
import uia from "../../assets/uia.svg";
import bag from "../../assets/bag.svg";
import media from "../../assets/media.svg";
import SecBtn from "../../Components/SecBtn";
import FadeInAnimation from "../../Components/FadeIn";
import { useNavigate } from "react-router-dom";

function ListProjectPromtScreen() {
  const [selectedItems, setSelectedItems] = useState<any>(null);
  const navigate = useNavigate();
  const prTypes = [
    {
      type: "A Project (Under my company)",
      desText:
        "Ready to share your next big idea? List a project under your company for investors to discover.",
      icon: uia,
      delay: 0.5,
    },
    {
      type: "A Talent (Under my company)",
      desText:
        "Ready to showcase your stars? List a talent signed under your company for investors to discover.",
      icon: bag,
      delay: 1,
    },
    {
      type: "A Talent (Self listed)",
      desText:
        "Let’s get your talent noticed and funded! Showcase your skills, past work and potential.",
      icon: media,
      delay: 1.5,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="dash-body " style={{ paddingBottom: 100 }}>
      <SideNav activeTab="Projects" />
      <div className="content-body">
        <ProfileNav />
        <div className="list-option-body">
          <FadeInAnimation delay={0.3} duration={1}>
            <h4>What or who are you listing?</h4>
          </FadeInAnimation>
          {prTypes.map((item: any, index) => (
            <FadeInAnimation delay={item.delay} duration={1}>
              <div
                className={`pr-item-cont ${
                  selectedItems === item.type ? "pr-active" : ""
                }`}
                onClick={() => {
                  console.log(item);
                  setSelectedItems(item.type);
                }}
              >
                <div className="pr-text-wrap">
                  <h4>{item.type}</h4>
                  <p>{item.desText}</p>
                </div>
                <img src={item.icon} alt="" />
              </div>
            </FadeInAnimation>
          ))}
          <FadeInAnimation delay={2} duration={1}>
            <div className="pr-btn">
              <SecBtn
                disabled={selectedItems == null}
                style={{
                  height: "100%",
                  width: "100%",
                  borderRadius: 12,
                  fontSize: 14,
                  fontWeight: 500,
                }}
                onPress={() => {
                  if (
                    selectedItems?.includes("A Talent (Self listed)") ||
                    selectedItems?.includes("A Talent (Under my company)")
                  ) {
                    navigate("/ListTalent", { state: { type: selectedItems} });
                  } else {
                    navigate("/ListProject")
                  }
                }}
                extraClass={` ${selectedItems === null ? "" : "m-pr-btn"} `}
                text="Continue"
              />
            </div>
          </FadeInAnimation>
        </div>
      </div>
    </div>
  );
}

export default ListProjectPromtScreen;
