import React, { useState } from "react";
import mClose from "../assets/close.svg";
import Input from "./Input";
import { useNavigate } from "react-router-dom";
import TextArea from "./Textera";
interface PProps {
  onClose?: any;
  cload?: boolean;
}
function RepIssues({ onClose }: PProps) {
  const navigate = useNavigate();
  const [vName, setVName] = useState("");
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({
    vName: "",
    description: "",
  });
  const validateField = (fieldName: string, value: string) => {
    switch (fieldName) {
      case "vName":
        return value.trim() === "" ? "Voucher Number is required" : "";
      case "description":
        return value.trim() === "" ? "Description is required" : "";
      default:
        return "";
    }
  };
  const handleInputChange = (fieldName: string, value: string) => {
    switch (fieldName) {
      case "vName":
        setVName(value);
        break;
      case "description":
        setDescription(value);
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: validateField(fieldName, value),
    }));
  };
  const isFormValid = () => {
    const validationResults = {
      vName: validateField("vName", vName),
      description: validateField("description", description),
    };

    setErrors((prevErrors) => ({
      ...prevErrors,
      ...validationResults,
    }));

    return Object.values(validationResults).every((error) => error === "");
  };

  const handleSubmit = async () => {
    if (isFormValid()) {
      navigate("/IssueReported");
      onClose();
    }
  };

  return (
    <div className="rep-issue">
      <div className="del-promt-nav">
        <p>Report issue</p>
        <button style={{ all: "unset", cursor: "pointer" }} onClick={onClose}>
          <img src={mClose} alt="" />
        </button>
      </div>
      <div className="rep-inputs-cont">
        <Input
          type="text"
          contStyle={{
            height: 64,
            marginBottom: 16,
            backgroundColor: "#0D0D0D",
          }}
          extraClass="rep-input rep-input-input"
          label="Voucher number"
          value={vName}
          onChange={(e) => handleInputChange("vName", e.target.value)}
          error={errors.vName ? true : false}
        />
        <TextArea
          height={197}
          extraClass="textarea textarea1 rep-input"
          label="Describe the issue"
          value={description}
          bgColor="#0D0D0D"
          onChange={(e: any) => {
            handleInputChange("description", e.target.value);
          }}
          error={errors.description ? true : false}
          labelAtTop={true}
        />
      </div>
      <div className="dd-btns del-btns">
        <button
          onClick={() => {
            onClose()
          }}
        >
          Cancel
        </button>
        <button
          style={{ backgroundColor: "#fff", color: "#000" }}
          onClick={() => {
            handleSubmit();
          }}
        >
          Report issue
        </button>
      </div>
    </div>
  );
}

export default RepIssues;
