import React, { useEffect, useState } from "react";
import SideNav from "../../Components/SideNav";
import ProfileNav from "../../Components/ProfileNav";
import { useLocation, useNavigate } from "react-router-dom";
import chevronB from "../../assets/ion_chevron-back.svg";
import FadeInTop from "../../Components/FadeInTop";
import city from "../../assets/living.png";
import LineChartSingle from "../../Components/LineChartSingle";
import pdf from "../../assets/pdf.svg";
import arrowOutward from "../../assets/arrowOutward.svg";
import { GetSpotlightById } from "../../RequestHandlers/Spotlight";

function SpotlightDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("Project details");
  const [prStatus, setPrStatus] = useState("progress");
  const tabs = [{ name: "Project details", delay: 0.2 }];
  const [roi, setRoi] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>({});
  
  const { id } = location.state || {}; 

   const getSpotlightById = async (id:number) => {
        setIsLoading(true)
        try {
          const res = await GetSpotlightById(id);
          console.log(res);
       setData(res);
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
        };
      }

  useEffect(() => {
   getSpotlightById(id)
  }, [])
  
  

  return (
    <div className="dash-body">
      <SideNav activeTab="Spotlight" />
      <div className="content-body">
        <ProfileNav />
        <div className="section-ph">
          <div className="ph-text-sec">
            <div style={{ width: "100%" }}>
              <button
                onClick={() => {
                  navigate(-1);
                }}
                className="back"
                style={{
                  all: "unset",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  gap: 4,
                  cursor: "pointer",
                }}
              >
                <img src={chevronB} alt="" />
                Back
              </button>
            </div>
            <h4 style={{ fontSize: 20, marginTop: 16 }}>{data?.title}</h4>
          </div>
        </div>
        <div className="tab-nav">
          {tabs.map((item, index) => {
            return (
              <FadeInTop delay={item.delay} duration={1}>
                <button
                  className={`${activeTab === item.name ? "activeTab" : ""}`}
                >
                  {item.name}
                </button>
              </FadeInTop>
            );
          })}
        </div>
        {isLoading && <div className="text-white h-[180px] justify-center items-center flex w-full text-center mt-8">Loading...</div>}
        {!isLoading && <div className="spotlight-details">
          <div className="details-wrap1">
            <h4 className="dtl-h4">About project</h4>
            <p className="dtl-p">{data?.description}</p>
            <div className="dtl-overview">
              <h4 className="dtl-h4">Project overview</h4>
              <div className="dtl-ovo">
                <div className="dtl-item">
                  <p className="dtl-p">Industry</p>
                  <h5 className="dtl-p" style={{ color: "#fff" }}>
                    {data?.owner?.industry}
                  </h5>
                </div>
                <div className="dtl-item">
                  <p className="dtl-p">Funding goal</p>
                  <h5 className="dtl-p" style={{ color: "#fff" }}>
                    $5,000,000
                  </h5>
                </div>
                <div className="dtl-item">
                  <p className="dtl-p">Funding round</p>
                  <h5 className="dtl-p" style={{ color: "#fff" }}>
                    Pre-seed
                  </h5>
                </div>
                <div className="dtl-item">
                  <p className="dtl-p">Avalable Units</p>
                  <h5 className="dtl-p" style={{ color: "#fff" }}>
                    {data?.unitsAcquired}
                  </h5>
                </div>
                <div className="dtl-item">
                  <p className="dtl-p">Unit price</p>
                  <h5 className="dtl-p" style={{ color: "#fff" }}>
                    $2,500
                  </h5>
                </div>
                <div
                  className="dtl-item"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <p className="dtl-p">Status</p>
                  <div
                    className={`f-item capitalize ${prStatus.includes("Failed")
                        ? "f-item-error"
                        : prStatus.includes("progress")
                          ? "f-item-prog"
                          : prStatus.includes("Draft")
                            ? "f-item-draft"
                            : ""
                      }`}
                    style={{ width: "auto", marginTop: 6 }}
                  >
                    {data?.status?.replace("-", " ")}
                  </div>
                </div>
              </div>
            </div>
            <h4 className="dtl-h4" style={{ marginTop: 40 }}>
              Project gallery
            </h4>
            <div className="pr-gallary-sec">
              {data?.galaries?.map(
                (item: any) => {
                  return <img key={item.id} src={item.link} alt="" />;
                }
              )}
            </div>
            <div className="dtl-overview">
              <h4 className="dtl-h4">Company contact</h4>
              <div className="dtl-ovo">
                <div className="dtl-item">
                  <p className="dtl-p">Website</p>
                  <h5 className="dtl-p" style={{ color: "#fff" }}>
                    {data?.owner?.website}
                  </h5>
                </div>
                <div className="dtl-item">
                  <p className="dtl-p">LinkedIn</p>
                  <h5 className="dtl-p" style={{ color: "#fff" }}>
                    {data?.owner?.socials[0]}
                  </h5>
                </div>
                <div className="dtl-item">
                  <p className="dtl-p">Email</p>
                  <h5 className="dtl-p" style={{ color: "#fff" }}>
                    {data?.owner?.contactEmail}
                  </h5>
                </div>
                <div className="dtl-item">
                  <p className="dtl-p">Contact</p>
                  <h5 className="dtl-p" style={{ color: "#fff" }}>
                    {data?.owner?.contactPhoneNumber}
                  </h5>
                </div>
              </div>
            </div>
            <h4 className="dtl-h4" style={{ marginTop: 40 }}>
              Industry achievements
            </h4>
            <div className="pr-gallary-sec">
              <ol style={{ marginTop: 6 }}>
                {["Leadership recognition", "Industry awards"].map(
                  (item, index) => {
                    return (
                      <li style={{ color: "#fff", fontWeight: 400 }}>
                        {index + 1}. {item}
                      </li>
                    );
                  }
                )}
              </ol>
            </div>
          </div>
          <div className="details-wrap2">
            <h4 className="dtl-h4">Projected ROI</h4>
            <div
              className="l-chart--hold"
              style={{ borderRadius: 10, marginTop: 12 }}
            >
              <LineChartSingle roiData={roi} />
            </div>
            <h4 className="dtl-h4" style={{ marginTop: 40 }}>
              Company achievements
            </h4>
            <div
              className="l-chart--hold"
              style={{
                borderRadius: 10,
                marginTop: 12,
                display: "flex",
                flexDirection: "column",
                backgroundColor: "transparent",
              }}
            >
              {[
                "Green Haven.PDF",
                "Eco Lofts Complex",
                "Company Registration.PDF",
              ].map((item, index) => {
                return (
                  <a href="" className="ex-link">
                    <button>
                      <div>
                        <img src={pdf} alt="" /> {item}
                      </div>
                      <img src={arrowOutward} alt="" />
                    </button>
                  </a>
                );
              })}
            </div>
          </div>
        </div>}
      </div>
    </div>
  );
}

export default SpotlightDetail;
