import { useNavigate, useParams } from "react-router-dom";
import "../../css/profile/styles.css";

const ProfileTabHeader = ({ activeOption, setActiveOption }: any) => {
    const navigate = useNavigate();
    const { activeTab } = useParams<{ activeTab: string }>();

    const options = [
        {
            name: "Profile Information",
            value: "profile-information",
        },
        {
            name: "Password",
            value: "password",
        },
        {
            name: "Notification preference",
            value: "notification-preference",
        },
        {
            name: "Currency preference",
            value: "currency-preference",
        },
    ];

    const handleOptionClick = (value: string) => {
        setActiveOption(value);
        navigate(`/ProfileScreen/${value}`);
    };

    return (
        <div className="profile-tab-header-view">
            <div className="profile-tab-header-container">
                {options.map(({ name, value }) => (
                    <div
                        onClick={() => handleOptionClick(value)}
                        style={{
                            backgroundColor: activeOption === value ? "#FFFFFF" : "transparent",
                        }}
                        key={value}
                        className="profile-tab-option"
                    >
                        <p
                            style={{
                                color: activeOption === value ? "#030500" : "#A5A5A5",
                            }}
                        >
                            {name}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProfileTabHeader;
