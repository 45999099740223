import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler
);

interface PProps {
  roiData?: any;
}

const LineChartSingle = ({ roiData }: PProps) => {
  ChartJS.defaults.font.family = "Figtree";

  // Map backend data to chart labels and dataset
  const labels = roiData.map((item: any) => item.name); // X-axis labels
  const dataPoints = roiData.map((item: any) => item.percentage); // Y-axis data

  const data = {
    labels, // Dynamically set labels from backend
    datasets: [
      {
        label: "ROI",
        data: dataPoints, // Dynamically set data from backend
        borderColor: "rgba(138, 43, 226, 1)", // Purple line
        backgroundColor: "", // Light purple fill
        fill: false, // Enable fill under the line
        tension: 0.4, // Smooth curve
        pointRadius: 0,
        pointHoverRadius: 5,
        pointBackgroundColor: "#FFFFFF", // White points
        pointBorderColor: "rgba(138, 43, 226, 1)", // Purple border for points
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "rgba(255, 255, 255, 0.8)", // White text
          font: {
            size: 14,
          },
        },
      },
      y: {
        grid: {
          color: "rgba(255, 255, 255, 0.1)", // Light grid lines
          lineWidth: 1,
        },
        ticks: {
          callback: (value: number) => `${value}%`, // Add '%' to y-axis labels
          stepSize: 5, // Interval between ticks
          max: Math.max(...dataPoints) + 5, // Maximum value dynamically
          color: "#fff", // White text
          font: {
            size: 14,
          },
        },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          title: (tooltipItems: any) => `${tooltipItems[0].label}`, // Display the label
          label: (tooltipItem: any) => `${tooltipItem.raw}% ROI`, // Show percentage
        },
        backgroundColor: "#FFFFFF", // White tooltip background
        titleColor: "#000000", // Black text for the title
        displayColors: false,
      },
    },
    elements: {
      line: {
        borderWidth: 2,
      },
      point: {
        hoverRadius: 8, // Enlarge point on hover
      },
    },
  };

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "298px",
        padding: "20px",
        borderRadius: "15px",
      }}
    >
      {/* @ts-ignore */}
      <Line data={data} options={options} />
    </div>
  );
};

export default LineChartSingle;
