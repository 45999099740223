export const removeMediaFromStorage = async (
  type: "images" | "videos",
  fileName: string,
  key: string
) => {
  try {
    // Retrieve the object from localStorage
    const storedData = localStorage.getItem(key);
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      // Filter out the item to be removed
      parsedData[type] = parsedData[type].filter(
        (item: any) => item.fileName !== fileName
      );
      // Save the updated object back to localStorage
      localStorage.setItem(key, JSON.stringify(parsedData));
      console.log(`${fileName} removed from ${type}.`);
      return parsedData; // Optional: return updated data for further use
    } else {
      console.log("No data found in localStorage.");
    }
  } catch (error) {
    console.error("Error removing media from storage:", error);
  }
};
