export function timeConverter(timestamp: string): string {
    const now = new Date();
    const date = new Date(timestamp);
    const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

    const seconds = 60;
    const minutes = 60 * seconds;
    const hours = 24 * minutes;
    const days = 7 * hours;
    const weeks = 4 * days;
    const months = 12 * weeks;
    const years = 365 * days;

    if (diffInSeconds < seconds) {
        return `${diffInSeconds} seconds ago`;
    } else if (diffInSeconds < minutes) {
        const mins = Math.floor(diffInSeconds / seconds);
        return `${mins} min${mins > 1 ? "s" : ""} ago`;
    } else if (diffInSeconds < hours) {
        const hrs = Math.floor(diffInSeconds / minutes);
        return `${hrs} hr${hrs > 1 ? "s" : ""} ago`;
    } else if (diffInSeconds < days) {
        const dayCount = Math.floor(diffInSeconds / hours);
        return `${dayCount} day${dayCount > 1 ? "s" : ""} ago`;
    } else if (diffInSeconds < weeks) {
        const weekCount = Math.floor(diffInSeconds / days);
        return `${weekCount} week${weekCount > 1 ? "s" : ""} ago`;
    } else if (diffInSeconds < months) {
        const monthCount = Math.floor(diffInSeconds / weeks);
        return `${monthCount} month${monthCount > 1 ? "s" : ""} ago`;
    } else if (diffInSeconds < years) {
        const yearCount = Math.floor(diffInSeconds / months);
        return `${yearCount} year${yearCount > 1 ? "s" : ""} ago`;
    } else {
        return "a long time ago";
    }
}
