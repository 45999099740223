import React, { CSSProperties, useEffect, useState } from "react";
import eye from "../assets/ion_eye.svg";
import eyeOff from "../assets/ion_eye-off.svg";

interface PProps {
  label?: string;
  name?:string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  extraClass?: string;
  isPassword?: boolean; // New prop for password
  error?: boolean |any;
  errorText?: string|any;
  contStyle?: CSSProperties;
  contClass?:string;
  labelStyle?:CSSProperties;
  disabled?:any;
}

export default function Input({
  label = "",
  value = "",
  name="",
  onChange,
  type = "text",
  extraClass = "",
  isPassword = false, // Default is false
  error,
  errorText,
  contStyle,
  contClass,
  labelStyle,
  disabled,
}: PProps) {
  const [isFocused, setIsFocused] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false); // State to toggle password visibility
  const inputId = label?.toLowerCase().replace(/\s+/g, "-") || "input";

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e);
    setIsFocused(e.target.value !== "");
  };

  useEffect(() => {
    if (value !== "" && value !== undefined) {
      setIsFocused(true);
    }
  }, [value]);

  return (
    <div>
      <div
        className={`input-container ${isFocused ? "focused" : ""} ${
          error ? "inputError" : ""
        } ${contClass}`}
        style={contStyle}
      >
        {label && (
          <label className="floating-label" htmlFor={inputId} style={labelStyle}>
            {label}
          </label>
        )}
        <div className="input-wrapper">
          <input
          name={name}
            style={{ width: isPassword ? "85%" : "100%" }}
            id={inputId} // Associate the input with the label
            type={isPassword && !isPasswordVisible ? "password" : type}
            className={`floating-input ${extraClass}`}
            value={value}
            onChange={handleChange}
            onFocus={() => setIsFocused(true)}
            disabled={disabled}
            onBlur={(e) => {setIsFocused(e.target.value !== "")}}
          />
          {isPassword && (
            <button
              type="button"
              className="toggle-password-visibility"
              onClick={() => setIsPasswordVisible((prev) => !prev)}
            >
              <img src={isPasswordVisible ? eye : eyeOff} alt="" />
            </button>
          )}
        </div>
      </div>
      {error && <p className="error-text">{errorText}</p>}
    </div>
  );
}
