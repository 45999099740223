import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler
);

const LargeChart = ({
  backendData,
}: {
  backendData: { month: string; amount: number }[];
}) => {
  ChartJS.defaults.font.family = "Figtree";

  // Process backend data to fit the chart
  const processData = (data: { month: string; amount: number }[]) => {
    const maxLabels = 7;

    // Determine the grouping size
    const groupSize = Math.ceil(data.length / maxLabels);

    // Group data and calculate averages for y-values
    const groupedData = [];
    for (let i = 0; i < data.length; i += groupSize) {
      const group = data.slice(i, i + groupSize);
      const avgAmount =
        group.reduce((sum, item) => sum + item.amount, 0) / group.length;
      groupedData.push({
        month: group[0].month, // Use the first month in the group
        amount: avgAmount,
      });
    }

    return groupedData;
  };

  // Dynamically process data
  const chartData = processData(backendData);

  // Extract labels and data
  const labels = chartData.map((item) => item.month);
  const values = chartData.map((item) => item.amount);

  // Calculate y-axis max value and step size dynamically
  const maxYValue = Math.ceil(Math.max(...values) / 10) * 10; // Round up to the nearest 10
  const minYValue = Math.floor(Math.min(...values) / 10) * 10; // Round down to the nearest 10
  const stepSize = (maxYValue - minYValue) / 7; // Calculate step size for ticks

  // Chart data
  const data = {
    labels,
    datasets: [
      {
        label: "Value",
        data: values,
        borderColor: "rgba(138, 43, 226, 1)", // Purple line
        backgroundColor: "#9421ff2d",
        fill: true,
        tension: 0.4, // Smooth curve
        pointRadius: 0,
        pointHoverRadius: 6,
        pointBackgroundColor: "#FFFFFF", // White points
        pointBorderColor: "rgba(138, 43, 226, 1)", // Purple border for points
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "rgba(255, 255, 255, 0.8)", // White text
          font: {
            size: 14,
          },
        },
      },
      y: {
        grid: {
          color: "rgba(255, 255, 255, 0.1)", // Light grid lines
          lineWidth: 1,
        },
        ticks: {
          callback: (value: number) => `${Math.round(value)}`, // Round values to the nearest whole number
          stepSize: stepSize, // Dynamically calculated step size
          min: minYValue, // Dynamically calculated min value
          max: maxYValue, // Dynamically calculated max value
          color: "#fff", // White text
          font: {
            size: 14,
          },
        },
        beginAtZero: false, // Start at the calculated minimum
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          title: (tooltipItems: any) => `${tooltipItems[0].label}`, // Display the label
          label: (tooltipItem: any) => `$${tooltipItem.raw}`, // Show raw value
        },
        backgroundColor: "#FFFFFF", // White tooltip background
        titleColor: "#000000",
        bodyColor: "#000000",
        displayColors: false,
      },
    },
    elements: {
      line: {
        borderWidth: 2,
      },
      point: {
        hoverRadius: 8, // Enlarge point on hover
      },
    },
  };

  // Component rendering
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "420px",
        borderRadius: "15px",
        padding: "20px",
      }}
    >
      {/* @ts-ignore */}
      <Line data={data} options={options} />
    </div>
  );
};

export default LargeChart;
