
import React, { useEffect, useRef, useState } from "react";
import closeIcon from "../../assets/close-circle.svg";
import trophyIcon from "../../assets/trophy-circle.svg";
import infoIcon from "../../assets/info-cricle-with-bg.svg";
import medalIcon from "../../assets/medal-circle.svg";
import starIcon from "../../assets/star-medal.svg";
import giftIcon from "../../assets/gift-circle.svg";
import documentCheck from "../../assets/document-check.svg";
import { useNavigate } from "react-router-dom";
import { GetNotifications, MarkAllNotificationAsSeen, MarkNotificationAsSeen } from "../../RequestHandlers/Notifications";
import "./style.css"
import { timeConverter } from "../../utils/time-converter";
import Toast, { handleToast } from "../Toast";
interface Notification {
  title: string;
  body: string;
  icon: string;
  updated_at: string;
  id:string
}

interface NotificationDropDownProps {
  setOpenNot: (open: boolean) => void;
  openNot: boolean;
}

interface NotificationListProps {
  title: string;
  description: string;
  updated_at: string;
  icon: string;
  setOpenNot: (open: boolean) => void;
  navigate: any;
  id:string;
  setNotifications:any
  notifications:Notification[]
}

const NotificationDropDown = ({ setOpenNot, openNot }: NotificationDropDownProps) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  const getNotificationsHandler = async () => {
    try {
      const response = await GetNotifications();
      console.log("response",response)
      if (response) {
        setNotifications(response?.items?.filter(({isSeen}:any)=>isSeen === false)?.slice(0, 5));
      }
    } catch (error) {
      console.log("Error occurred while fetching notifications", error);
    }
  };


  const markAllAdReadHandler =async()=>{
    try {
      const response = await MarkAllNotificationAsSeen();
      setNotifications([])
      handleToast("All notification marked as read","success")
    } catch (error:any) {
      console.log("Error occured while marking notification read",error)
      handleToast(error?.message ||"Network error","error")
    }
  }
  useEffect(() => {
    getNotificationsHandler();
  }, []);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setOpenNot(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setOpenNot]);

  return (
    <div
      ref={dropdownRef}
      style={{
        display: openNot ? "block" : "none",
      }}
      className="notification-container"
    >
      <div className="notification-title-view">
        <p className="notification-title">Notifications</p>
        <div
          onClick={() => {
            setOpenNot(false);
          }}
        >
          <img className="close-icon" src={closeIcon} />
        </div>
      </div>

      <div style={{ width: "100%" }}>
        {notifications.length > 0 ? (
          <>
            {notifications?.map(({ title, icon, body, updated_at,id }, index) => (
              <NotificationList
              setNotifications={setNotifications}
              notifications={notifications}
              id={id}
                setOpenNot={setOpenNot}
                navigate={navigate}
                key={index}
                updated_at={updated_at}
                title={title}
                description={body}
                icon={icon}
              />
            ))}
          </>
        ) : (
          <div className="no-notification-view">
            <img src={documentCheck} />
            <p className="no-notification-title">No notifications yet</p>
            <p className="no-notification-description">
              Your notifications will appear here.
            </p>
          </div>
        )}
      </div>

      {notifications.length > 0 && (
        <div className="mark-read-view">
          <p
            onClick={() => {
              setOpenNot(false);
              markAllAdReadHandler()
            }}
            className="notification-mark-read-text"
          >
            Mark all as read
          </p>
          <p
            onClick={() => {
              setOpenNot(false);
              navigate("/NotificationScreen");
            }}
            className="notification-see-all"
          >
            See all
          </p>
        </div>
      )}
      <Toast/>
    </div>
  );
};

export default NotificationDropDown;

const NotificationList = ({
  title,
  updated_at,
  description,
  icon,
  setOpenNot,
  navigate,
  id,
  setNotifications,
  notifications
}: NotificationListProps) => {
  const marksreadHandler =async(notId:any)=>{
    try {
      const response = await MarkNotificationAsSeen(notId);
      console.log("notification marked",response)
      const newItem =  notifications.filter(({id})=>id !== notId)
      setNotifications(newItem)
      handleToast("Notification marked as read","success")
    } catch (error:any) {
      console.log("Error occured while marking notification read",error)
      handleToast(error?.message ||"Network error","error")
    }
  }
  return (
    <div
      // onClick={(e) => {
      //   setOpenNot(false);
      //   navigate("/NotificationScreen"); 
      // }}
      className="notification-list-view"
    >
      <div className="notification-list-container">
        <div className="notification-list-icon-view">
          <img src={icon} alt="notification icon" />
        </div>
        <div className="notification-info-view">
          <div style={{ width: "100%" }}>
            <p className="notification-info-title">{title}</p>
            <p className="notification-info-description">{description}</p>
          </div>
          <div className="notification-list-action-view">
            <p onClick={(e)=>{
             e.stopPropagation()
              marksreadHandler(id)
            }} className="notification-dismiss">Dismiss</p>
            <p className="notification-time">{timeConverter(updated_at)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
