import React, { useEffect, useState, useRef } from "react";

interface TextAreaProps {
  label?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  extraClass?: string;
  height?: any;
  error?: boolean;
  errorText?: string;
  bgColor?: string;
  labelAtTop?: boolean; // New prop to position label at the top
}

export default function TextArea({
  label = "",
  value = "",
  onChange,
  extraClass,
  height = 94,
  error,
  errorText,
  bgColor,
  labelAtTop = false, // Default is false
}: TextAreaProps) {
  const [isFocused, setIsFocused] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) onChange(e);
    adjustHeight(e.target);
    setIsFocused(e.target.value !== "");
  };

  const adjustHeight = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = "auto"; // Reset height to calculate new scroll height
    const scrollHeight = textarea.scrollHeight;
    textarea.style.height = `${Math.max(scrollHeight, height)}px`; // Maintain at least the specified height
  };

  useEffect(() => {
    if (value && value !== "") {
      setIsFocused(true);
    }

    if (textAreaRef.current) {
      adjustHeight(textAreaRef.current); // Adjust height on component mount or value change
    }
  }, [value]);

  const uniqueId = `textarea-${label?.replace(/\s+/g, "-").toLowerCase()}`;

  return (
    <>
      <div
        className={`input-container ${isFocused || labelAtTop ? "focused" : ""} ${
          error ? "inputError" : ""
        }`}
        style={{ minHeight: height, height: "auto", backgroundColor: bgColor }}
      >
        {label && (
          <label
            className={`floating-label ${labelAtTop ? "label-at-top" : ""}`}
            htmlFor={uniqueId} // Link label to textarea
          >
            {label}
          </label>
        )}
        <textarea
          id={uniqueId} // Match id with label's htmlFor
          ref={textAreaRef}
          className={`floating-input ${extraClass || ""}`}
          value={value}
          onChange={handleChange}
          onFocus={() => setIsFocused(true)}
          onBlur={(e) => setIsFocused(e.target.value !== "")}
          rows={1}
          style={{
            resize: "none",
            overflow: "hidden",
            minHeight: `${height}px`,
          }}
        />
      </div>
      {error && <p className="error-text">{errorText}</p>}
    </>
  );
}
