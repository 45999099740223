import React, { useEffect, useState } from "react";

interface ProgressRingProps {
  radius: number; // Radius of the circle
  stroke: number; // Stroke width
  progress: number;
  text?: string;
  backgroundColor?:string
}

const ProgressRing: React.FC<ProgressRingProps> = ({
  radius,
  stroke,
  progress,
  text,
  backgroundColor = "#FBFFF2"
}) => {
  const normalizedRadius = radius - stroke / 2; // Adjusted for stroke width
  const circumference = 2 * Math.PI * normalizedRadius; // Circumference of the circle
  const [strokeDashoffset, setStrokeDashoffset] = useState(circumference);

  // Update strokeDashoffset whenever progress changes
  useEffect(() => {
    const newStrokeDashoffset =
      circumference - (progress / 100) * circumference;
    setStrokeDashoffset(newStrokeDashoffset);
  }, [progress, circumference]);

  return (
    <svg
      height={radius * 2}
      width={radius * 2}
      style={{ transform: "rotate(-90deg)" }} // Rotate to start at the top
    >
      {/* Background Circle */}
      <circle
        stroke={backgroundColor}
        fill="transparent"
        strokeWidth={stroke}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      {/* Progress Circle */}
      <circle
        stroke="#a7fc00"
        fill="transparent"
        strokeWidth={stroke}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
        strokeDasharray={circumference}
        strokeDashoffset={strokeDashoffset}
        strokeLinecap="round"
        style={{
          transition: "stroke-dashoffset 1s ease-in-out", // Animation effect
        }}
      />
      {/* Text in the Center */}
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        dy=".3em"
        fontSize="13.33px"
        fill="#a7fc00"
        style={{
          transform: "rotate(90deg)",
          transformOrigin: "center",
        }}
      >
        {text}
      </text>
    </svg>
  );
};

export default ProgressRing;
