import React, { useRef, useState, useEffect } from "react";
import SideNav from "../../Components/SideNav";
import ProfileNav from "../../Components/ProfileNav";
import { useLocation, useNavigate } from "react-router-dom";
import ProgressRing from "../../Components/ProgressRing";
import chevBack from "../../assets/ion_chevron-back.svg";
import Input from "../../Components/Input";
import { useFileUpload } from "../../context/uploadFile";
import chevDown from "../../assets/chev-down.svg";
import NumberMonthDropdown from "../../Components/NumberMonthDropdown";
import plus from "../../assets/plus.svg";
import colon from "../../assets/colon.svg";
import { GetIndustries } from "../../RequestHandlers/Auth";
import Cookies from "js-cookie";
import Toast, { handleToast } from "../../Components/Toast";
interface Row {
  months?: string;
  roi: string;
}
function CreateNewVoucher2() {
  const navigate = useNavigate();
  const [ceoName, setCeoName] = useState("");
  const [activeIndustry, setActiveIndustry] = useState<any>(null);
  const [activeVoucherValidity, setActiveVoucherValidity] = useState<any>(null);
  const [dropActive, setDropActive] = useState(false);
  const [dropActive1, setDropActive1] = useState(false);
  const [industries, setIndustries] = useState<any>(null);
  const [validities, setValidities] = useState<any>(null);
  const [ceoLinkdin, setCeoLinkdin] = useState("");
  const [cwu, setCwu] = useState("");
  const [bl, setBl] = useState("");
  const [au, setAu] = useState("");
  const [vnp, setVnp] = useState("");
  const [usef, setUseF] = useState("");
  const location = useLocation();
  const V1data = location.state.data || {};
  const [errors, setErrors] = useState<any>({});
  const formatWithComma = (value: string | number): string => {
    if (!value) return "";
    return new Intl.NumberFormat("en-US").format(Number(value));
  };
  const handleFieldChange = (field: string, value: string) => {
    setErrors((prevErrors: any) => {
      const updatedErrors = { ...prevErrors };
      if (updatedErrors[field]) {
        delete updatedErrors[field];
      }
      return updatedErrors;
    });
  };

  const [rows, setRows] = useState<Row[]>([{ roi: "" }]);
  const numberofmonths = [
    { name: "3 Month", value: 90 },
    { name: "6 Months", value: 180 },
    { name: "1 year", value: 365 },
  ];
  const saveToDraft = (credentials: any) => {
    try {
      // Get the current value from localStorage
      const existingData = localStorage.getItem("vDraft");
      // Parse the existing data if it exists
      const parsedData = existingData ? JSON.parse(existingData) : {};
      // Merge the new credentials with the existing data
      const updatedData = {
        ...parsedData,
        ...credentials, // This will overwrite any matching keys
      };
      // Save the updated data back to localStorage
      localStorage.setItem("vDraft", JSON.stringify(updatedData));
      // Notify the user
      handleToast("Saved to draft", "success");
      console.log(updatedData);
    } catch (err) {
      console.error("Error updating credentials:", err);
      handleToast("Error saving to draft", "error");
    }
  };

  const getDraftDetails = () => {
    try {
      // Retrieve the data from localStorage
      const savedData = localStorage.getItem("vDraft");
      if (savedData) {
        const credentials = JSON.parse(savedData);
        console.log(credentials);

        // Update state if specific fields exist
        if (credentials.addedvalue) {
          setCeoName(credentials.ceoName);
          setCeoLinkdin(credentials.ceoLinkdin);
          setActiveIndustry(credentials.activeIndustry);
          setCwu(credentials.cwu);
          setBl(credentials.bl);
          setAu(credentials.au);
          setVnp(credentials.vnp);
          setActiveVoucherValidity(credentials.activeVoucherValidity);
          setRows(credentials.addedvalue);
        }

        return credentials; // Return the parsed credentials
      } else {
        console.log("No draft found.");
        return null;
      }
    } catch (err) {
      console.error("Error retrieving draft details:", err);
      return null;
    }
  };

  const getIndustries = async () => {
    try {
      const res = await GetIndustries();
      setIndustries(res);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getIndustries();
    getDraftDetails();
  }, []);
  const handleInputChange = (
    index: number,
    field: keyof Row,
    value: string
  ) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };
  const handleDropdownChange = (
    index: number,
    selectedOption: { name: string; value: any }
  ) => {
    const updatedRows = [...rows];
    updatedRows[index].months = selectedOption.value;
    setRows(updatedRows);
  };

  const getFilteredOptions = (index: number) => {
    const selectedValues = rows
      .map((row, rowIndex) => (rowIndex !== index ? row.months : null)) // Exclude the current row's selected value
      .filter((value) => value !== null); // Filter out nulls
    console.log(selectedValues);

    return numberofmonths.filter(
      // @ts-ignore
      (month) => !selectedValues.includes(month.value)
    );
  };
  const handleFormSubmit = (e: React.FormEvent, type: string) => {
    e.preventDefault();
    console.log("Form submission attempted");
    const newErrors: Record<string, string> = {};
    // Global field validations
    console.log(newErrors);

    if (ceoName.trim() === "") {
      newErrors.ceoName = "Field is required";
    }
    if (ceoLinkdin === "") {
      newErrors.ceoLinkdin = "Field is required";
    }
    if (cwu.trim() === "") {
      newErrors.cwu = "Field is required";
    }
    if (bl.trim() === "") {
      newErrors.bl = "Field is required";
    }
    if (activeIndustry === null) {
      newErrors.activeIndustry = "Field is required";
    }
    if (activeVoucherValidity === null) {
      newErrors.activeVoucherValidity = "Field is required";
    }
    if (au.trim() === "") {
      newErrors.au = "Field is required";
    }
    if (vnp.trim() === "") {
      newErrors.vnp = "Field is required";
    }
    rows.slice(0, 1).forEach((item, index) => {
      if (item.roi === "") {
        newErrors[`roi${index}`] = "Field is required";
      }
    });

    // If errors exist, set them and stop submission
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // If no errors, proceed with form submission
    console.log("Form submitted successfully:", {
      ceoName,
      ceoLinkdin,
      cwu,
      bl,
      activeIndustry,
      activeVoucherValidity,
    });
    // Clear errors and navigate
    const data = {
      ceoName: ceoName,
      ceoLinkdin: ceoLinkdin,
      cwu: cwu,
      bl: bl,
      activeIndustry: activeIndustry,
      activeVoucherValidity: activeVoucherValidity,
      au: au,
      vnp: vnp,
      addedvalue: rows,
    };
    if (type === "draft") {
      saveToDraft(data);
    } else {
      navigate("/CreateNewVoucher3", {
        state: {
          data: { ...V1data, ...data },
        },
      });
    }
  };
  return (
    <div className="dash-body" style={{ minHeight: "110vh" }}>
      <Toast />
      <SideNav activeTab="Vouchers" />
      <div className="content-body">
        <ProfileNav />
        <div
          className="c-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src={chevBack} alt="" />
          <p>Back</p>
        </div>
        <div className="sec-header">
          <p>Create New Voucher</p>
          <ProgressRing
            radius={20}
            stroke={4}
            progress={66.6}
            text={"2/3"}
            backgroundColor="#111900"
          />
        </div>
        <div className="list-content-holder">
          <div className="content-cont">
            <p className="ov">Business Overview</p>
            <div className="option-holders">
              <div className="input-holder">
                <Input
                  contClass="list-input-cont"
                  extraClass="list-input"
                  label="CEO’s Name"
                  contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                  value={ceoName}
                  onChange={(e) => {
                    setCeoName(e.target.value);
                    handleFieldChange("ceoName", e.target.value);
                  }}
                  error={errors.ceoName}
                  errorText={errors.ceoName}
                />
              </div>
              <div className="input-holder">
                <Input
                  contClass="list-input-cont"
                  extraClass="list-input"
                  label="CEO’s Linkedin URL "
                  contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                  value={ceoLinkdin}
                  onChange={(e) => {
                    setCeoLinkdin(e.target.value);
                    handleFieldChange("ceoLinkdin", e.target.value);
                  }}
                  error={errors.ceoLinkdin}
                  errorText={errors.ceoLinkdin}
                />
              </div>
            </div>
            <div className="option-holders">
              <div className="input-holder">
                <Input
                  contClass="list-input-cont"
                  extraClass="list-input"
                  label="Company Website URL"
                  contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                  value={cwu}
                  onChange={(e) => {
                    setCwu(e.target.value);
                    handleFieldChange("cwu", e.target.value);
                  }}
                  error={errors.cwu}
                  errorText={errors.cwu}
                />
              </div>
              <div className="input-holder">
                <Input
                  contClass="list-input-cont"
                  extraClass="list-input"
                  label="Business Location"
                  contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                  value={bl}
                  onChange={(e) => {
                    setBl(e.target.value);
                    handleFieldChange("bl", e.target.value);
                  }}
                  error={errors.bl}
                  errorText={errors.bl}
                />
              </div>
            </div>
            <div className="option-holders">
              <div className="dropdown-holder" style={{ width: "100%" }}>
                <div
                  className={`reg-file-cont drop-down ${
                    activeIndustry !== null ? "focused" : ""
                  } ${errors.activeIndustry ? "img-cont-error" : ""}`}
                  onClick={() => {
                    setDropActive(!dropActive);
                  }}
                >
                  <label className="floating-label">Industry</label>
                  <div className="active-ind">{activeIndustry?.name}</div>
                  <div className="chev">
                    <img
                      src={chevDown}
                      alt=""
                      className={`${dropActive ? "rotate" : ""}`}
                    />
                  </div>
                </div>
                {errors.activeIndustry && (
                  <p className="error-text">{errors.activeIndustry}</p>
                )}
                {dropActive && (
                  <div className="in-drop-down">
                    {industries.map((item: any, index: number) => {
                      return (
                        <div
                          key={index}
                          className={`ind ${
                            item === activeIndustry ? "ind-active" : ""
                          }`}
                          onClick={() => {
                            setActiveIndustry(item);
                            setDropActive(false);
                            handleFieldChange("activeIndustry", item.name);
                          }}
                        >
                          {item.name}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>

            <div className="option-holders">
              <div className="input-holder">
                <Input
                  contClass="list-input-cont"
                  extraClass="list-input"
                  label="Available Units"
                  contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                  value={au}
                  onChange={(e) => {
                    setAu(e.target.value); // Update state without the dollar sign
                    handleFieldChange("au", e.target.value); // Handle errors
                  }}
                  error={errors.au}
                  errorText={errors.au}
                />
              </div>
              <div className="input-holder">
                <Input
                  contClass="list-input-cont"
                  extraClass="list-input"
                  label="Voucher Unit Price"
                  contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                  value={vnp ? `$${formatWithComma(vnp)}` : ""}
                  onChange={(e) => {
                    const inputValue = e.target.value.replace(/[^0-9]/g, "");
                    setVnp(inputValue);
                    handleFieldChange("vnp", inputValue);
                  }}
                  error={errors.vnp}
                  errorText={errors.vnp}
                />
              </div>
            </div>
            <div className="option-holders">
              <div className="dropdown-holder" style={{ width: "100%" }}>
                <div
                  className={`reg-file-cont drop-down ${
                    activeVoucherValidity !== null ? "focused" : ""
                  } ${errors.activeVoucherValidity ? "img-cont-error" : ""}`}
                  onClick={() => {
                    setDropActive1(!dropActive1);
                  }}
                >
                  <label className="floating-label">Voucher Validity</label>
                  <div className="active-ind">
                    {activeVoucherValidity?.name}
                  </div>
                  <div className="chev">
                    <img
                      src={chevDown}
                      alt=""
                      className={`${dropActive1 ? "rotate" : ""}`}
                    />
                  </div>
                </div>
                {errors.activeVoucherValidity && (
                  <p className="error-text">{errors.activeVoucherValidity}</p>
                )}
                {dropActive1 && (
                  <div className="in-drop-down">
                    {numberofmonths.map((item: any, index: number) => {
                      return (
                        <div
                          key={index}
                          className={`ind ${
                            item === activeVoucherValidity ? "ind-active" : ""
                          }`}
                          onClick={() => {
                            setActiveVoucherValidity(item);
                            setDropActive1(false);
                            handleFieldChange(
                              "activeVoucherValidity",
                              item.name
                            );
                          }}
                        >
                          {item.name}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="content-cont">
            <p className="ov">Added value</p>
            {rows.map((item, index) => {
              return (
                <div className="option-holders">
                  <div className="input-holder" style={{ width: "100%" }}>
                    <Input
                      contClass="list-input-cont"
                      extraClass="list-input"
                      label="Added value"
                      contStyle={{ height: 64, backgroundColor: "#0d0d0d" }}
                      value={item.roi}
                      onChange={(e) => {
                        handleInputChange(index, "roi", e.target.value);
                        handleFieldChange(`roi${index}`, item.roi);
                      }}
                      error={!!errors[`roi${index}`]} // Highlight the input in error
                      errorText={errors[`roi${index}`]}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="std-cont">
          <div
            className="std-btn"
            onClick={(e) => {
              handleFormSubmit(e, "draft");
            }}
          >
            Save to draft
          </div>
          <div
            className="c-btn"
            onClick={(e) => {
              handleFormSubmit(e, "submit");
            }}
          >
            Continue
          </div>
        </div>
        <div style={{ width: "100%", height: 100 }}></div>
      </div>
    </div>
  );
}

export default CreateNewVoucher2;
