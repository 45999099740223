import React, { useState, useRef, useEffect } from "react";
import chevDown from "../assets/chev-down.svg";

interface DropdownProps {
  options: { name: string; value: any }[]; // Options to display
  label: string; // Floating label
  selected: { name: string; value: any } | null; // Current selected item
  onSelect: (option: { name: string; value: any }) => void; // Callback on selection
  errorText?: string;
}

const NumberMonthDropdown: React.FC<DropdownProps> = ({
  options,
  label,
  selected,
  onSelect,
  errorText = null,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className={`dropdown-holder`} ref={dropdownRef}>
      <div
        className={`reg-file-cont drop-down ${
          selected != null ? "focused" : ""
        } ${errorText ? "img-cont-error" : ""}`}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <label className="floating-label">{label}</label>
        <div className="active-ind">{selected?.name || ""}</div>
        <div className="chev">
          <img src={chevDown} alt="" />
        </div>
      </div>
      {errorText && <p className="error-text">{errorText}</p>}

      {isOpen && (
        <div className="in-drop-down">
          {options.map((option, index) => (
            <div
              key={index}
              className={`ind ${
                selected?.value === option.value ? "ind-active" : ""
              }`}
              onClick={() => {
                onSelect(option);
                setIsOpen(false);
              }}
            >
              {option.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default NumberMonthDropdown;
{
  /* <div className="dropdown-holder">
<div
  className={`reg-file-cont drop-down ${
    activeIndustry !== null ? "focused" : ""
  }`}
  onClick={() => {
    setShowFD(!showFd);
  }}
>
  <label className="floating-label">Number of Months</label>
  <div className="active-ind">{activeIndustry?.name}</div>
  <div className="chev">
    <img src={chevDown} alt="" />
  </div>
</div>

{showFd && (
  <div className="in-drop-down">
    {industries.map((item: any, index: number) => {
      return (
        <div
          key={index}
          className={`ind ${
            item === activeIndustry ? "ind-active" : ""
          }`}
          onClick={() => {
            setActiveIndustry(item);
            setDropActive(false);
          }}
        >
          {item.name}
        </div>
      );
    })}
  </div>
)}
</div> */
}
