import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import tsbLogo from "../assets/logo.svg";
import { useMediaQuery } from "react-responsive";
import "../css/responsive.css";
import hambug from "../assets/hambug.svg";
import cancel from "../assets/cancel.svg";
import { motion } from "framer-motion";

interface PProps {
  currentActive?: string;
}

export default function NavBar({ currentActive = "About TBS" }: PProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showLogo, setShowLogo] = useState(false);
  const [activeLink, setActiveLink] = useState(-1);
  const [activeLink1, setActiveLink1] = useState(-1);
  const isMobile = useMediaQuery({ maxWidth: 567 });
  const [toggleMenu, setToggleMenu] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Expand the navbar
    const expandTimeout = setTimeout(() => {
      setIsExpanded(true);
    }, 500);

    // Show the logo after the navbar expands
    const logoTimeout = setTimeout(() => {
      setShowLogo(true);
    }, 1500);
    // Animate primary links
    const primaryLinksTimeout = setTimeout(() => {
      let step = 0;
      const primaryLinksInterval = setInterval(() => {
        setActiveLink((prev) => prev + 1);
        step++;
        if (step === 2) clearInterval(primaryLinksInterval);
      }, 300);
    }, 1800);

    // Animate secondary links
    const secondaryLinksTimeout = setTimeout(() => {
      let step = 0;
      const secondaryLinksInterval = setInterval(() => {
        setActiveLink1((prev) => prev + 1);
        step++;
        if (step === 2) clearInterval(secondaryLinksInterval);
      }, 300);
    }, 2300);

    // Cleanup timeouts and intervals
    return () => {
      clearTimeout(expandTimeout);
      clearTimeout(logoTimeout);
      clearTimeout(primaryLinksTimeout);
      clearTimeout(secondaryLinksTimeout);
    };
  }, []);

  return (
    <nav
      className={`navbar ${isExpanded && !isMobile ? "expanded" : ""} ${
        toggleMenu ? "expandNav" : ""
      }`}
    >
      <div className="navbar-content">
        {/* Logo */}
        <div className={`logo ${showLogo ? "visible" : ""}`}>
          <img src={tsbLogo} alt="TBS Logo" className="tbsLogo" />
        </div>
        {isMobile && (
          <button
            onClick={() => {
              setToggleMenu(!toggleMenu);
            }}
          >
            <img src={toggleMenu ? cancel : hambug} alt="" />
          </button>
        )}
        {isMobile && toggleMenu && (
          <div className="menu-content">
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0.2 }}
              className="text-[18px] text-white font-semibold"
              onClick={() => navigate("/")}
              style={{
                color: currentActive === "About TBS" ? "A7FC00" : "#fff",
              }}
            >
              About TBS
            </motion.p>
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0.4 }}
              className="text-[18px] font-semibold mt-[24px]"
              onClick={() => navigate("/whatIsTokenization")}
              style={{
                color: currentActive === "What is Tokenizations" ? "A7FC00" : "#fff",
              }}
            >
              What is Tokenization
            </motion.p>
            <ul className="nav-links1">
              <motion.li
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.8 }}
                className={`nav-item lgLink1 ${
                  activeLink1 >= 0 ? "visible" : ""
                }`}
                onClick={() => navigate("/LoginScreen")}
              >
                Login
              </motion.li>
              <motion.li
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.6 }}
                className={`nav-item lgLink2 ${
                  activeLink1 >= 1 ? "visible" : ""
                }`}
                onClick={() => navigate("/tokenizewithus")}
              >
                Tokenize with us
              </motion.li>
            </ul>
          </div>
        )}
        {/* Nav Links */}
        {!isMobile && (
          <>
            <ul className="nav-links">
              <li
                className={`nav-item ${activeLink >= 0 ? "visible" : ""} ${
                  currentActive === "About TBS" ? "active" : ""
                }`}
                onClick={() => navigate("/")}
              >
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.6641 15.3578H11.3307L7.62239 17.8244C7.07239 18.1911 6.33073 17.7995 6.33073 17.1328V15.3578C3.83073 15.3578 2.16406 13.6911 2.16406 11.1911V6.19108C2.16406 3.69108 3.83073 2.02441 6.33073 2.02441H14.6641C17.1641 2.02441 18.8307 3.69108 18.8307 6.19108V11.1911C18.8307 13.6911 17.1641 15.3578 14.6641 15.3578Z"
                    stroke={`${
                      currentActive === "About TBS" ? "#A7FC00" : "#E6E6E5"
                    }`}
                    strokeWidth="1.25"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.5005 9.4668V9.29183C10.5005 8.72516 10.8505 8.42515 11.2005 8.18348C11.5422 7.95015 11.8838 7.65016 11.8838 7.10016C11.8838 6.33349 11.2672 5.7168 10.5005 5.7168C9.73383 5.7168 9.11719 6.33349 9.11719 7.10016"
                    stroke={`${
                      currentActive === "About TBS" ? "#A7FC00" : "#E6E6E5"
                    }`}
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.4989 11.4587H10.5064"
                    stroke={`${
                      currentActive === "About TBS" ? "#A7FC00" : "#E6E6E5"
                    }`}
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p>About TBS</p>
              </li>
              <li
                className={`nav-item ${activeLink >= 1 ? "visible" : ""} ${
                  currentActive === "What is Tokenization" ? "active" : ""
                }`}
                onClick={() => navigate("/whatIsTokenization")}
              >
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.14062 6.2002L10.499 10.4585L17.8073 6.2252"
                    stroke={`${
                      currentActive === "What is Tokenization"
                        ? "#A7FC00"
                        : "#E6E6E5"
                    }`}
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.5 18.0085V10.4502"
                    stroke={`${
                      currentActive === "What is Tokenization"
                        ? "#A7FC00"
                        : "#E6E6E5"
                    }`}
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.77552 2.06706L4.32552 4.53373C3.31719 5.09206 2.49219 6.49206 2.49219 7.64206V12.3504C2.49219 13.5004 3.31719 14.9004 4.32552 15.4587L8.77552 17.9337C9.72552 18.4587 11.2839 18.4587 12.2339 17.9337L16.6839 15.4587C17.6922 14.9004 18.5172 13.5004 18.5172 12.3504V7.64206C18.5172 6.49206 17.6922 5.09206 16.6839 4.53373L12.2339 2.05873C11.2755 1.53373 9.72552 1.53373 8.77552 2.06706Z"
                    stroke={`${
                      currentActive === "What is Tokenization"
                        ? "#A7FC00"
                        : "#E6E6E5"
                    }`}
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p>What is Tokenization</p>
              </li>
            </ul>

            {/* Secondary Links */}
            <div className="secBtns">
              <ul className="nav-links1">
                <li
                  className={`nav-item lgLink1 ${
                    activeLink1 >= 0 ? "visible" : ""
                  }`}
                  onClick={() => navigate("/LoginScreen")}
                >
                  Log in
                </li>
                <li
                  className={`nav-item lgLink2 ${
                    activeLink1 >= 1 ? "visible" : ""
                  }`}
                  onClick={() => navigate("/tokenizewithus")}
                >
                  Tokenize with us
                </li>
              </ul>
            </div>
          </>
        )}
      </div>
    </nav>
  );
}
