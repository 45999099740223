import React, { useEffect, useState } from "react";
import HeaderBar from "../Components/HeaderBar";
import { useNavigate } from "react-router-dom";
import Input from "../Components/Input";
import FadeInAnimation from "../Components/FadeIn";
import TextArea from "../Components/Textera";
import checkActive from "../assets/checkActive.svg";
import checkBox from "../assets/Checkbox.svg";
import SecBtn from "../Components/SecBtn";

import Toast, { handleToast } from "../Components/Toast";
import { TokenizeRequest } from "../RequestHandlers/Auth";
import { useMediaQuery } from "react-responsive";

export default function TokenizeWithUs() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 567 });
  const [isCheckActive, setIsCheckActive] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState(
    "I want to tokenize my business or a project"
  );
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    reason: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validateField = (fieldName: string, value: string) => {
    switch (fieldName) {
      case "firstName":
        return value.trim() === "" ? "First name is required" : "";
      case "lastName":
        return value.trim() === "" ? "Last name is required" : "";
      case "email":
        if (value.trim() === "") {
          return "Email is required";
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return !emailRegex.test(value) ? "Invalid email address" : "";
      case "phone":
        if (value.trim() === "") {
          return "Phone number is required";
        }
        const phoneRegex = /^\d{10,15}$/;
        return !phoneRegex.test(value) ? "Invalid phone number" : "";
      case "reason":
        return value.trim() === "" ? "Reason is required" : "";
      default:
        return "";
    }
  };

  const handleInputChange = (fieldName: string, value: string) => {
    // Update the corresponding state
    switch (fieldName) {
      case "firstName":
        setFirstName(value);
        break;
      case "lastName":
        setLastName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "phone":
        setPhone(value);
        break;
      case "reason":
        setReason(value);
        break;
    }

    // Validate the field and update errors
    const error = validateField(fieldName, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: error,
    }));
  };

  const isFormValid = () => {
    return (
      Object.values(errors).every((error) => error === "") &&
      firstName &&
      lastName &&
      email &&
      phone &&
      reason
    );
  };

  const sendTkRequest = async () => {
    setLoading(true);
    try {
      const body = {
        email: email.trim(),
        firstname: firstName.trim(),
        lastname: lastName.trim(),
        reason: reason.trim(),
        phone: phone.trim(),
      };

      const res = await TokenizeRequest(body);
      console.log(res);
      if (res.id) {
        setLoading(false);
        navigate("/emailsent");
      } else {
        setLoading(false);
        handleToast(res.message, "error");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (!isFormValid()) return;
    // handleToast("Form submitted successfully", "success");
    // Add form submission logic here
    sendTkRequest();
  };

  return (
    <>
      <Toast />
      <div className="tk-reg-body">
        <HeaderBar
          onclick={() => {
            navigate(-1);
          }}
        />
        <div className="pageCont">
          <FadeInAnimation delay={0.3} duration={1}>
            <h4 className="pageH4">Ready to tokenize?</h4>
          </FadeInAnimation>
          <FadeInAnimation delay={0.6} duration={1}>
            <p className="pageP">
              Take the first step towards tokenization by contacting our
              tokenization team to discuss your project and see if it qualifies
              for our platform.
            </p>
          </FadeInAnimation>
          <div className="inputCont">
            <div className="inputWrap">
              <FadeInAnimation delay={0.9} duration={1}>
                <Input
                  type="text"
                  label="First Name"
                  value={firstName}
                  onChange={(e) =>
                    handleInputChange("firstName", e.target.value)
                  }
                  error={errors.firstName ? true : false}
                  errorText={errors.firstName}
                />
              </FadeInAnimation>
            </div>
            <div className="inputWrap">
              <FadeInAnimation delay={1.2} duration={1}>
                <Input
                  type="text"
                  label="Last Name"
                  value={lastName}
                  onChange={(e) =>
                    handleInputChange("lastName", e.target.value)
                  }
                  error={errors.lastName ? true : false}
                  errorText={errors.lastName}
                />
              </FadeInAnimation>
            </div>
          </div>
          <div className="inputCont1">
            <FadeInAnimation delay={1.5} duration={1}>
              <Input
                type="text"
                label="Email Address"
                value={email}
                onChange={(e) => handleInputChange("email", e.target.value)}
                error={errors.email ? true : false}
                errorText={errors.email}
              />
            </FadeInAnimation>
          </div>
          <div className="inputCont1">
            <FadeInAnimation delay={1.8} duration={1}>
              <Input
                type="text"
                label="Phone Number"
                value={phone}
                onChange={(e) => handleInputChange("phone", e.target.value)}
                error={errors.phone ? true : false}
                errorText={errors.phone}
              />
            </FadeInAnimation>
          </div>
          <div className="inputCont1">
            <FadeInAnimation delay={2.1} duration={1}>
              <TextArea
                extraClass="textarea"
                label="Message"
                value={reason}
                onChange={(e) => handleInputChange("reason", e.target.value)}
                error={errors.reason ? true : false}
                errorText={errors.reason}
              />
            </FadeInAnimation>
          </div>

          <div className="bottomCont">
            <FadeInAnimation delay={2.5} duration={1}>
              <div className="checkCont">
                <div
                  className="checkBtn"
                  onClick={() => {
                    setIsCheckActive(!isCheckActive);
                  }}
                >
                  <img src={isCheckActive ? checkActive : checkBox} alt="" />
                </div>
                <p>
                  I agree to receiving newsletters and marketing communications
                  from Trendx.
                  {!isMobile && <br />}
                  (This is optional and can be canceled from your inbox
                  anytime).
                </p>
              </div>

              {!isMobile && (
                <p className="linkText">
                  Already listed a project?
                  <span
                    className="link"
                    onClick={() => {
                      navigate("/LoginScreen");
                    }}
                  >
                    Log in
                  </span>
                </p>
              )}
            </FadeInAnimation>
          </div>
            <div className="sec-btnHolder">
              <SecBtn
              extraClass="w-80"
                text="Continue"
                onPress={handleSubmit}
                disabled={!isFormValid()}
                loading={loading}
              />
            </div>
        </div>
      </div>
    </>
  );
}
