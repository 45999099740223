import React, { useState, useEffect, useRef } from "react";
import SideNav from "../Components/SideNav";
import ProfileNav from "../Components/ProfileNav";
import { useNavigate } from "react-router-dom";
import plus from "../assets/outline_plus.svg";
import ValuesCard from "../Components/ValuesCard";
import fi from "../assets/fi.svg";
import vfi from "../assets/vfi.svg";
import pfi from "../assets/pfi.svg";
import arc from "../assets/arc.svg";
import FadeInAnimation from "../Components/FadeIn";
import c1 from "../assets/c1.png";
import c2 from "../assets/c2.png";
import c3 from "../assets/c3.png";
import c4 from "../assets/c4.png";
import c5 from "../assets/c5.png";
import g1 from "../assets/g1.png";
import g2 from "../assets/g2.png";
import g3 from "../assets/g3.png";
import g4 from "../assets/g4.png";
import g5 from "../assets/g5.png";
import dropdownicon from "../assets/icon-park_down.svg";
import LineChart from "../Components/LineChart";
import emptyList from "../assets/empty-list.svg";
import emptyChart from "../assets/empty-chart.svg";
import { GetUserFundChart, GetUserOverview } from "../RequestHandlers/User";
import { GetCollection } from "../RequestHandlers/Collection";
import { formatDate } from "../Components/formatDate";

function Dashboard() {
  const navigate = useNavigate();
  const loaderArr = [1, 2, 3, 4];
  const cloadArr = [1, 2, 3, 4, 5, 6];
  const [loading1, setLoading1] = useState(false);
  const [cload, setCLoad] = useState(false);
  const [activeValue, setActiveValue] = useState("Last 14 days");
  const [activeDuration, setActiveDuration] = useState("last-14-days");
  const [vLoad, setVLoad] = useState(false);
  const [projectCount, setPCount] = useState(0);
  const [vCount, setVCount] = useState(0);
  const [iCount, setICount] = useState(0);
  const [fundingProgress, setFundingProgress] = useState(0);
  const [chartData, setChartData] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [status, setStatus] = useState("all");

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const allAmountsAreZero = chartData.every((item: any) =>
    item.data.every((entry: any) => entry.amount === 0)
  );

  const chartDurations = [
    { mDate: "Last 14 days", bDate: "last-14-days" },
    { mDate: "Last 30 days", bDate: "last-30-days" },
    { mDate: "Last 60 days", bDate: "last-60-days" },
    { mDate: "Last 90 days", bDate: "last-90-days" },
    { mDate: "Last 120 days", bDate: "last-120-days" },
  ];
  const [data, setData] = useState<any>([]);

  // const backendData = [
  //   {
  //     projectName: "Cityscape living",
  //     data: [
  //       { date: "Sep 7", amount: 1000 },
  //       { date: "Sep 11", amount: 500 },
  //       { date: "Sep 16", amount: 1500 },
  //     ],
  //   },
  //   {
  //     projectName: "Truffel skin",
  //     data: [
  //       { date: "Sep 7", amount: 2000 },
  //       { date: "Sep 11", amount: 200 },
  //       { date: "Sep 16", amount: 1200 },
  //     ],
  //   },
  //   {
  //     projectName: "Mawi",
  //     data: [
  //       { date: "Sep 7", amount: 500 },
  //       { date: "Sep 11", amount: 1500 },
  //       { date: "Sep 16", amount: 1000 },
  //     ],
  //   },
  //   {
  //     projectName: "Tech hub",
  //     data: [
  //       { date: "Sep 7", amount: 500 },
  //       { date: "Sep 11", amount: 1500 },
  //       { date: "Sep 16", amount: 400 },
  //     ],
  //   },
  //   // More projects...
  // ];

  const getOwnerOverview = async () => {
    setVLoad(true);
    try {
      const res = await GetUserOverview();
      if (res.projectCount) {
        setPCount(res.projectCount);
        setVCount(res.voucherCount);
        setICount(res.investorCount);
        setFundingProgress(res.fundingProgress);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        setVLoad(false);
      }, 2000);
    }
  };

  const getChartdata = async () => {
    setCLoad(true);
    try {
      const res = await GetUserFundChart(activeDuration);
      console.log("chart data", res);
      setChartData(res.projectCharts);
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        setCLoad(false);
      }, 2000);
    }
  };
  const getCollecttion = async () => {
    setLoading1(true);
    try {
      const res = await GetCollection(page, limit);
      console.log("projects", res);
      setData(res.items);
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        setLoading1(false);
      }, 2000);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getOwnerOverview();
    getCollecttion();
  }, []);
  useEffect(() => {
    getChartdata();
  }, [activeDuration]);
  return (
    <div className="dash-body" ref={dropdownRef}>
      <SideNav activeTab="Dashboard" />
      <div className="content-body">
        <ProfileNav />
        <div className="section-ph">
          <div className="ph-text-sec">
            <h4>Dashboard</h4>
            <p>Real-time overview of your listed projects</p>
          </div>
          <div
            className="lnp-btn"
            onClick={() => {
              navigate("/ListProjectPromtScreen");
            }}
          >
            <img src={plus} alt="" /> List new project
          </div>
        </div>
        <div className="dash-overview">
          <div className="card-holder">
            <div className="holder1">
              <ValuesCard
                extraClass={"item-126"}
                delay={0.5}
                text1="All projects"
                icon={fi}
                loading={vLoad}
                bottomComp={
                  <>
                    <p className="count-value">{projectCount} listed</p>
                  </>
                }
              />

              <ValuesCard
                delay={1}
                extraClass={"item-126"}
                text1="Brand vouchers"
                loading={vLoad}
                icon={vfi}
                bottomComp={
                  <>
                    <p className="count-value">{vCount} listed</p>
                  </>
                }
              />
            </div>
            <div className="holder2">
              <ValuesCard
                delay={1.5}
                text1="Investor count"
                loading={vLoad}
                icon={pfi}
                bottomComp={
                  <>
                    <p className="count-value">{iCount} investors</p>
                  </>
                }
              />
              <ValuesCard
                delay={2}
                text1="Funding progress"
                icon={arc}
                loading={vLoad}
                bottomComp={
                  <div>
                    <p className="count-value v1">{fundingProgress}%</p>
                    <div className="custom-slider">
                      <div
                        className="track"
                        style={{ width: `${fundingProgress}%` }}
                      >
                        <div className="thumb">
                          <div className="thumb-inner"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              />
            </div>
          </div>
          <div className="chart-holder" ref={dropdownRef}>
            <FadeInAnimation duration={1} delay={1.5}>
              <div className="ch-header" ref={dropdownRef}>
                <p>Funds raised</p>
                <div
                  className="drop-holder"
                  style={{
                    width: 200,
                    // background: 'red',
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <div
                    // ref={dropdownRef}
                    className="drop-btn"
                    onClick={() => setIsOpen((prev) => !prev)}
                  >
                    {activeValue}{" "}
                    <img
                      className={`${isOpen ? "rotate" : ""}`}
                      src={dropdownicon}
                      alt=""
                    />
                  </div>
                  {isOpen && (
                    <div className="duration-drop">
                      {chartDurations.map((item, index) => {
                        return (
                          <div
                            className={`duration-text ${
                              activeDuration === item.bDate
                                ? "duration-active"
                                : ""
                            }`}
                            onClick={() => {
                              setActiveDuration(item.bDate);
                              setActiveValue(item.mDate);
                              setIsOpen(false);
                            }}
                          >
                            {item.mDate}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
              {chartData.length === 0 || cload ? (
                <></>
              ) : (
                <div className="ch-header2">
                  <p>$1,520.50</p>
                  {chartData.length > 1 && (
                    <div className="active-crbs">
                      {chartData.slice(0, 5).map((item: any, index: number) => {
                        const itemWidth = `${100 / data.length}%`;
                        return (
                          <div className="crbs">
                            <div
                              style={{
                                width: 12,
                                height: 3,
                                marginRight: 10,
                                backgroundColor:
                                  index === 0
                                    ? "#683FEA"
                                    : index === 1
                                    ? "#A7FC00"
                                    : index === 2
                                    ? "#FC7900"
                                    : index === 3
                                    ? "#FFD700"
                                    : index === 4
                                    ? "#C3B2F7"
                                    : "#A7FC00",
                              }}
                            ></div>
                            <span
                              style={{
                                overflow: "hidden", // Hide overflowing text
                                whiteSpace: "nowrap", // Prevent wrapping
                                textOverflow: "ellipsis", // Show ellipsis for overflow
                                color: "#fff",
                                fontSize: 14,
                                flex: 1, // Allow the text to use remaining space
                              }}
                              title={item.projectName} // Full text on hover
                            >
                              {item.projectName}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
              <div
                className="main-chart-cont"
                style={{ marginTop: chartData.length === 0 || cload ? 43 : 33 }}
              >
                {cload ? (
                  <>
                    {cloadArr.map((_: any, ind: number) => (
                      <div
                        className={`cload-dash ${
                          ind === cloadArr.length - 1 ? "" : "bottom-26"
                        }`}
                      ></div>
                    ))}
                  </>
                ) : chartData.length === 0 || allAmountsAreZero ? (
                  <>
                    <div className="empty-wrap" style={{ marginTop: 24 }}>
                      <img src={emptyChart} alt="" />
                      <p>No data Available</p>
                      <span>Your chart will be here</span>
                    </div>
                  </>
                ) : (
                  <LineChart backendData={chartData} />
                )}
              </div>
            </FadeInAnimation>
          </div>
        </div>
        <div className="lp-cont">
          <div className="lp-header">
            <h4>Listed project</h4>
            <div className="vr-btn">View report</div>
          </div>
          <div className="lp-content-holder">
            {loading1 ? (
              <>
                <table className="table">
                  <thead className="th1">
                    <tr>
                      <th>Project</th>
                      <th>Status</th>
                      <th>Date Listed</th>
                      <th style={{ paddingLeft: 10 }}>Funding Goal</th>
                      <th style={{ paddingLeft: 10 }}>Funding Progress</th>
                      <th>Funding Deadline</th>
                    </tr>
                  </thead>
                  <tbody className="tBody">
                    {loaderArr.map((item: any) => (
                      <tr key={item.id}>
                        <td>
                          <div className="tr">
                            <div className="img-load"></div>
                            <div className="tr-text">
                              <div className="t-load"></div>
                              <div className="in-load"></div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="tr1">
                            <div className={`f-item-load`}></div>
                          </div>
                        </td>
                        <td>
                          <div className="tr1">
                            <div className="d-load"></div>
                          </div>
                        </td>
                        <td className="funding-goal">
                          <div className="tr1" style={{ paddingLeft: 10 }}>
                            <div className="d-load"></div>
                          </div>
                        </td>
                        <td>
                          <div className="tr1" style={{ paddingLeft: 10 }}>
                            <div
                              className="prog-bar-cont"
                              style={{ color: "#616161" }}
                            >
                              <div className="prog-bar">
                                <div
                                  className="bar"
                                  style={{
                                    width: "25%",
                                    backgroundColor: "#838383",
                                  }}
                                ></div>
                              </div>
                              <div className="prog-load"></div>
                            </div>
                          </div>
                        </td>
                        <td className="ddLine">
                          <div className="tr-last">
                            <div className="d-load"></div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : data.length === 0 ? (
              <>
                <div className="empty-wrap">
                  <img src={emptyList} alt="" />
                  <p>No project available</p>
                  <span>List of projects will appear here</span>
                </div>
              </>
            ) : (
              <FadeInAnimation delay={0.5} duration={1}>
                <table className="table">
                  <thead className="th1">
                    <tr>
                      <th>Project</th>
                      <th>Status</th>
                      <th>Date Listed</th>
                      <th style={{ paddingLeft: 10 }}>Funding Goal</th>
                      <th style={{ paddingLeft: 10 }}>Funding Progress</th>
                      <th>Funding Deadline</th>
                    </tr>
                  </thead>
                  <tbody className="tBody">
                    {data.map((item: any) => (
                      <tr key={item.id}>
                        <td style={{ cursor: "pointer" }}>
                          <div
                            className="tr"
                            style={{ cursor: "ponter" }}
                            onClick={() => {
                              navigate("/ProjectDetailsScreen", {
                                state: {
                                  data: { name: item.name, id: item.id },
                                },
                              });
                            }}
                          >
                            <img src={item.cover} alt="" />
                            <div className="tr-text">
                              <p>{item.name}</p>
                              <span>{item.industry}</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="tr1">
                            <div
                              className={`f-item ${
                                item.status.includes("Failed")
                                  ? "f-item-error"
                                  : item.status.includes("progress")
                                  ? "f-item-prog"
                                  : item.status.includes("Draft")
                                  ? "f-item-draft"
                                  : ""
                              }`}
                            >
                              {item.status === "active"
                                ? "Published"
                                : "Pending"}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="tr1">
                            {formatDate(item.created_at)}
                          </div>
                        </td>
                        <td className="funding-goal">
                          <div className="tr1" style={{ paddingLeft: 10 }}>
                            ${item?.fundingGoal?.toLocaleString()}
                          </div>
                        </td>
                        <td>
                          <div className="tr1" style={{ paddingLeft: 10 }}>
                            <div
                              className="prog-bar-cont"
                              style={{ color: "#616161" }}
                            >
                              <div className="prog-bar">
                                <div
                                  className="bar"
                                  style={{
                                    width: `${item?.fundingProgress * 100}%`,
                                  }}
                                ></div>
                              </div>
                              {item?.fundingProgress * 100}%
                            </div>
                          </div>
                        </td>
                        <td className="ddLine">
                          <div className="tr-last">
                            {" "}
                            {formatDate(item.fundindDeadline)}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </FadeInAnimation>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
